// vue
import { h, ref, computed, reactive, watch, onMounted } from 'vue';
import moment from 'moment';

// services
import BotsService from '@services/bots';
import StatsService from '@services/statsService';
import LocalStorage from '@services/ts/localStorage';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// i18n
import { useI18n } from 'vue-i18n';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@/store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useStats } from '@store/stats';

// UI
import {
    NCheckbox,
    useNotification } from 'naive-ui';

export default function (props, context) {
    // store
    const gl = useGl();
    const env = useEnv();
    const refs = useRefs();
    const stats = useStats();

    // naive-ui
    const notification = useNotification();

    // i18n
    const { t } = useI18n();

    // ranges
    const ranges = computed(() => refs.ranges);

    // vars
    const { _ } = window;
    const init = ref(false);
    const loading = ref(false);
    const actionType = ref();
    const revenueStats = ref();
    const showConfirmation = ref(false);

    // storage filters
    const storeFilterKey = '___filters_stats';
    let storeFilters = LocalStorage.getItem(storeFilterKey);

    // period
    const localDefaultPeriod = ref();
    const defaultPeriod = moment().subtract(7, 'days').format('YYYY-MM-DD') + ' ' + moment().add(7, 'days').format('YYYY-MM-DD');
    const storagePeriod = () => {
        const i = ranges.value.findIndex(({ id }) => id == storeFilters?.filters.period);

        const result = ~i
            ? ranges.value[i].value.join(' ')
            : storeFilters?.filters.period || defaultPeriod;

        if (!localDefaultPeriod.value) {
            localDefaultPeriod.value = result;
        }

        return result;
    };

    // set filter settings
    if (storeFilters == null) {
        LocalStorage.setItem(storeFilterKey, CRUD_PAGER_FACTORY({
            bots: {},
            exchanges: [-1],
            pairs: [-1],
            period: storagePeriod(),
            statuses: [-1],
            states: [-1],
            include_rates: false,
            include_rates_changed: false,
            targets: ['bots'],
            tags: [-1],
        }, {
            perPage: 10,
        }));

        storeFilters = LocalStorage.getItem(storeFilterKey);
    }
    
    const filters = reactive({
        ...storeFilters,
    });

    const filtersChanged = computed(() => {
        return Object.keys(filters.filters.bots).length
          || filters.filters.exchanges[0] !== -1
          || filters.filters.pairs[0] !== -1
          || filters.filters.statuses[0] !== -1
          || filters.filters.states[0] !== -1
          || filters.filters.include_rates
          || filters.filters.include_rates_changed
          || filters.filters.targets[0] !== 'bots'
          || filters.filters.tags[0] !== -1
          || filters.filters.period !== defaultPeriod;
    });

    const resetFilters = () => {
        filters.filters.bots = {};
        filters.filters.exchanges = [ -1 ];
        filters.filters.pairs = [ -1 ];
        filters.filters.period = localDefaultPeriod.value || defaultPeriod;
        filters.filters.statuses = [ -1 ];
        filters.filters.states = [ -1 ];
        filters.filters.include_rates = false;
        filters.filters.include_rates_changed = false;
        filters.filters.targets = ['bots'];
        filters.filters.tags = [-1];

        // set default period
        // LocalStorage.setItem('__filters_stats_period', localDefaultPeriod.value);
      
        botsGet();
    };
    const refFilters = computed(() => [
        {
            mode: 'exchanges',
            title: refs.localization.filters.stats.filters_stats_exchanges_label,
            refs: refs.exchanges,
            filter: 'filters.exchanges',
            seeable: filters.filters.exchanges[0] !== -1,
        }, {
            type: 'render',
            mode: 'date_picker',
            value: periods.value,
            filter: 'filters.period',
            title: refs.localization.filters.stats.filters_stats_period_label,
            seeable: true,
        }, {
            mode: 'bots',
            title: refs.localization.filters.stats.filters_stats_bots_label,
            refs: stats.botFilters,
            filter: 'filters.bots',
            seeable: !!Object.keys(filters.filters.bots).length,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.stats.filters_stats_statuses_label,
            refs: refs.botStatuses,
            filter: 'filters.statuses',
            seeable: filters.filters.statuses[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.stats.filters_stats_states_label,
            refs: refs.botStates,
            filter: 'filters.states',
            seeable: filters.filters.states[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.stats.filters_stats_taxonomy_label,
            refs: stats.tags,
            filter: 'filters.tags',
            seeable: filters.filters.tags[0] !== -1,
        }, {
            mode: 'filter_switch',
            value: filters.filters.include_rates,
            title: refs.localization.filters.stats.filters_stats_withdraw_rates_label,
            filter: 'filters.include_rates',
            seeable: !!filters.filters.include_rates,
        }, {
            mode: 'filter_button_groupe',
            refs: radios.value,
            value: filters.filters.targets[0],
            title: refs.localization.filters.stats.filters_stats_grouping_label,
            filter: 'filters.targets',
            seeable: filters.filters.targets[0] !== 'bots',
        },
    ]);
    const someCoins = {
        profit: 'profit_coin',
        profit_usdt: 'profit_usdt_coin',
        profit_wo_comm: '_profit_wo_comm_symbol',
        profit_percent: 'profit_percent',
        comm_amount_btc: '_comm_amount_btc_symbol',
        comm_amount_usdt: '_comm_amount_usdt_symbol',
        rb_comm_amount_btc: 'rb_comm_amount_btc_coin',
        rb_comm_amount_usdt: 'rb_comm_amount_usdt_coin',
        profit_usdt_wo_comm: '_profit_usdt_wo_comm_symbol',
    };

    const actions = computed(() => [
        'bots__start',
        'bots__stop',
        'bots__pause',
        'bots__restart',
        'bots__edit',
        'bots__delete',
        'bots__clone',
        'bots__archive',
        'bots__force_stop',
    ]);

    const periods = computed(() => {
        const arr = filters.filters.period?.split(' ');

        return [new Date(arr[0]).getTime(), new Date(arr[1]).getTime()];
    });

    const dashProfit = computed(() => (
        {
            title: stats.localization['trade_statistics_total_profit'],
            data: stats.botsDataTable?.revenue_stats,
            ciclos: stats.botsDataTable?.revenue_stats?.cycles.completed,
            stats: !stats.botsDataTable?.revenue_stats ? [] : (Object.entries(stats.botsDataTable?.revenue_stats.coins).map(([key, value]) => [...value, key]))
                .sort(([ , , a], [ , , b]) => {
                    if (a < b)
                        return -1;
                    if (a > b)
                        return 1;
                    return;
                }),
        }),
    );
    const isBots = computed(() => filters.filters.targets[0] === 'bots');
    const currentTable = computed(() => {
        return stats.isBots
            ? stats.botsDataTable
            : stats.datesDataTable;
    });
    const isDates = computed(() => filters.filters.targets[0] === 'dates');
    const actionButtonsDisabled = computed(() => stats.botsDataTable.records ? stats.botsDataTable.records.some(({ checked }) => checked) : false);

    const radios = computed(() => [
        {
            value: stats.targetFilters[0].id,
            label: stats.targetFilters[0].title,
        }, {
            value: stats.targetFilters[1].id,
            label: stats.targetFilters[1].title,
        },
    ]);

    const columns = computed(() => {
        if (currentTable.value?.columns) {
            const arr = [...currentTable.value.columns]
                .map(el => {
                    if (el.name === '_cycles_completed') {
                        el.title = t('columns._cycles_completed');
                    }

                    return Object.prototype.hasOwnProperty.call(el, 'isHidden')
                        ? reactive({
                            ...el,
                        })
                        : reactive({
                            isHidden: false,
                            ...el,
                        });
                })
                .filter(({ title }) => title);
          
            if (stats.isBots)
                arr.unshift({
                    key: 'checked',
                    name: 'checked',
                    isHidden: false,
                    title() {
                        return h(NCheckbox, {
                            checked: currentTable.value?.records.every(({ checked }) => checked),
                            onUpdateChecked(v) {
                                currentTable.value.records.forEach(el => el.checked = v);
                            },
                        });
                    },
                });
          
            // console.log('columns', arr);
            return arr;
        } else return [];
    });

    watch(() => refs.lang, async () => {
        await getData();
    });

    const getData = async (showLoading = true) => {
        if (showLoading)
            gl.showLoading = true;

        try {
            if (!stats.refs)
                stats.refs = await StatsService.getRefs();
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        if (showLoading)
            gl.showLoading = false;
    };

    const changeFilter = ([ key, value, range ], update = false) => {
        const canUpdate = key === 'query' && !value && currentTable.value.pager.query;

        if (key !== 'page') {
            _.set(filters, 'page', 1);
        }

        if (key === 'perPage') {
            LocalStorage.setItem(storeFilterKey, value);
        }

        if (key === 'filters.period') {
            LocalStorage.setItem('__filters_stats_period', range || value);
        }

        if (key === 'filters.targets') {
            _.set(filters, key, value !== null && value !== undefined ? [ value ] : [ -1 ]);
        } else {
            _.set(filters, key, value !== null && value !== undefined ? value : [ -1 ]);
        }
      
        if (update || canUpdate)
            botsGet();
    };

    const botsGet = async (showLoading = true) => {
        if (showLoading)
            gl.showLoading = true;

        // save filters
        LocalStorage.setItem(storeFilterKey, filters);
          
        try {
            const prepare = await StatsService.botsGet({
                pager: filters,
            });

            const key = isBots.value ? 'botsDataTable' : 'datesDataTable';
            if (stats[key]) {
                Object.keys(prepare.data).forEach(el => {
                    if(!['columns', 'revenue_stats'].includes(el)) {
                        stats[key][el] = prepare.data[el];
                    };
                });
            } else {
                stats[key] = prepare.data;
            }

            stats.isBots = isBots.value;
            revenueStats.value = prepare.data.revenue_stats;

            if (stats[key].records.length)
                stats[key].records.push({
                    type: 'footer',
                    ...stats[key].totals,
                });
          
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        if (showLoading)
            gl.showLoading = false;
    };

    const currency = icon => refs.currencies.find(el => el.id === icon);

    const getAll = async () => {
        gl.showLoading = true;

        await Promise.allSettled([
            getData(false),
            botsGet(false),
        ]);

        gl.showLoading = false;
    };

    const doSomething = ([ action, value ]) => {
        const fns = {
            onQuestionInfo: () => {
                // showQuestionInfo.value = true;
                // questionInfo.value = marketplace.iMentorRecords.find(({ id }) => id === value);
            },
        };

        // fns[action]();
    };

    const groupAction = async action => {
        gl.showLoading = true;
        showConfirmation.value = false;

        try {
            const result = await BotsService.groupAction({
                ids: [...stats.botsDataTable.records.filter(({ checked }) => checked).map(({ id }) => id)],
                action,
            });

            if (result) {
                if (result.postMessages)
                    result.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });

                await botsGet();
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        stats.botsDataTable.records.forEach(el => el.checked = false);

        gl.showLoading = false;
    };

    const letsShowConfirmation = action => {
        actionType.value = action;
        showConfirmation.value = true;
    };

    onMounted(async () => {
        await getAll();

        init.value = true;
    });

    return {
        gl,
        env,
        init,
        refs,
        stats,
        radios,
        isBots,
        isDates,
        periods,
        filters,
        actions,
        columns,
        someCoins,
        dashProfit,
        refFilters,
        actionType,
        currentTable,
        revenueStats,
        filtersChanged,
        showConfirmation,
        actionButtonsDisabled,
        botsGet,
        currency,
        groupAction,
        changeFilter,
        doSomething,
        resetFilters,
        letsShowConfirmation,
    };
}