// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';

// services
import ApiKeysService from'@services/apiKeysService';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useApiKeys } from '@store/apiKeys';

// i18n
import { useI18n } from 'vue-i18n';

// naive-ui
import { useNotification } from 'naive-ui';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const apiKeys = useApiKeys();

    // vars
    const init = ref(false);
    const apiKeyInfo = ref();
    const dataForm = reactive({});
    const loadings = reactive({});
    const localLoading = ref(false);

    // ui
    const notification = useNotification();

    // i18n
    const { t } = useI18n();

    // computeds
    const apiKeyId = computed(() => props.apiKeyId);
    const isCreate = computed(() => !apiKeyId.value);
    const isUpdate = computed(() => !!apiKeyId.value);
    // const label = computed(() =>
    //     props.exchangeId
    //         ? props.apiKeyId ? apiKeys.localization?.apikeys_update_button : apiKeys.localization?.apikeys_title_button
    //         : null);
    const label = computed(() => props.apiKeyId ? apiKeys.localization?.apikeys_update_button : apiKeys.localization?.apikeys_title_button);
    const applyLabel = computed(() => props.apiKeyId ? apiKeys.localization?.apikeys_update_button : apiKeys.localization?.apikeys_add_button);
    const exchangeMetaInfo = computed(() => refs.exchanges.find(el => el.id === dataForm.exchange.value)?.meta);
    const exchangeMeta2Info = computed(() => refs.exchanges.find(el => el.id === dataForm.exchange.value)?.meta2);
    const canDoingSomethingWidthApiKey = computed(() => dataForm.name.value && dataForm.exchange.value && dataForm.key.value && dataForm.sign.value);
    const isExchangeSelected = computed(() => dataForm.exchange.value !== -1);

    const exchangeOptions = computed(() => refs.exchanges.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    // watchers
    watch(() => props.show, v => {
        if (v) {
            mainInit();
        } else {
            apiKeyInfo.value = null;
        }
    });

    // fns
    const exchange = exchange => refs.exchanges.find(el => el.id === exchange);

    const mainInit = async () => {
        init.value = false;

        await Promise.allSettled([
            apiKeyssRefs(),
            getFullApiKeyetInfo(),
        ]);

        initModule();

        init.value = true;
    };

    const getFullApiKeyetInfo = async () => {
        if (isUpdate.value) {
            try {
                apiKeyInfo.value = ( await ApiKeysService.getFullInfo(apiKeyId.value) ).data;
            } catch {
                gl.showNotification({
                    type: 'error',
                    msg: t('errorMessage'),
                });
            };
        }
    };

    const apiKeyssRefs = async () => {
        if (apiKeys.refs) return;

        try {
            apiKeys.refs = ( await ApiKeysService.getRefs() ).data;
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };
    };

    const initModule = () => {
        const res = {
            name: apiKeyInfo.value?.name || '',
            exchange: props.exchangeId || apiKeyInfo.value?.exchange || -1,
            key: apiKeyInfo.value?.key || '',
            sign: apiKeyInfo.value?.sign ||  '',
            show_dashboard: apiKeyInfo.value?.show_dashboard || false,
        };

        for (const [key, value] of Object.entries(res)) {
            if (key === 'name') {
                dataForm[key] = {
                    value,
                    title: apiKeys.localization['apikeys_name_f'],
                    placeholder: apiKeys.localization['apikeys_name_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'exchange') {
                dataForm[key] = {
                    value,
                    title: apiKeys.localization['apikeys_exchange_f'],
                    placeholder: apiKeys.localization['apikeys_exchange_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'key') {
                dataForm[key] = {
                    value,
                    title: apiKeys.localization['apikeys_key_f'],
                    placeholder: apiKeys.localization['apikeys_key_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'sign') {
                dataForm[key] = {
                    value,
                    title: apiKeys.localization['apikeys_sign_f'],
                    placeholder: apiKeys.localization['apikeys_sign_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'show_dashboard') {
                dataForm[key] = {
                    value,
                    title: apiKeys.localization['show_dashboard_label'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            };
        };
    };

    const onDoingSomethingWithApiKey = async () => {
        localLoading.value = true;

        const formPath = isCreate.value ? 'apikeys.addNew' : 'apikeys.update';

        const record = {
            exchange: dataForm.exchange.value,
            name: dataForm.name.value,
            key: dataForm.key.value,
            sign: dataForm.sign.value,
            show_dashboard: dataForm.show_dashboard.value,
            _formPath: formPath,
        };

        let result;

        // try {
        result = isCreate.value
            ? await ApiKeysService.addApiKeyToExchange(record.exchange, record)
            : await ApiKeysService.update({
                id: props.apiKeyId,
                ...record,
            });

        if (!result.data.status) {
            if (result.data?.errors_form) {

                for (let key in dataForm) {
                    const fields = result.data.errors_form[formPath].fields;
                    const el = Object.keys(fields).find(el => el === key);

                    if (el) {
                        dataForm[key].status = 'error';
                        dataForm[key].msg = fields[el].msg;
                    } else {
                        dataForm[key].status = 'success';
                        dataForm[key].msg = undefined;
                    }
                }
            }
              
            if (result.data?.errors) {
                result.data.errors.forEach(el => {
                    notification.error({
                        content: el.msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });
            }
        } else {
            context.emit('update:show', false);

            if (isUpdate.value) {
                context.emit('updateApiKey', result.data.record);
            } else {
                context.emit('addNewApiKey', result.data?.records[0]);
            }

            // clean apiKeyModel
            for (let key in dataForm) {
                dataForm[key].value = '';
            }

            // show messages
            result.postMessages.forEach(el => {
                gl.showNotification({
                    type: el.success ? 'success' : 'error',
                    msg: el.msg,
                });
            });
        }
        // } catch {
        //     notification.error({
        //         content: t('errorMessage'),
        //         duration: 2500,
        //         keepAliveOnHover: true,
        //     });
        // }

        localLoading.value = false;
    };

    const setLoadings = (fields = [], value = true) => {
        fields.forEach(key => {
            if (dataForm[key]?.loading != undefined) {
                dataForm[key].loading = value;
            }
        });
    };

    onMounted(() => {
        if (props.show) {
            mainInit();
        }
    });

    return {
        gl,
        init,
        label,
        apiKeys,
        dataForm,
        isUpdate,
        isCreate,
        applyLabel,
        localLoading,
        exchangeOptions,
        exchangeMetaInfo,
        exchangeMeta2Info,
        isExchangeSelected,
        canDoingSomethingWidthApiKey,
        exchange,
        onDoingSomethingWithApiKey,
    };
}