<template>
<label class="block w-full">
    <div :class="containerClasses">
        <div class="flex items-center" :class="labelClasses">
            <div
                class="text-gray-600 dark:text-white/75 text-xs text-left label"
                :class="titleClasses"
                v-html="label">
            </div>
            <n-popover
                v-if="help"
                scrollable
                trigger="hover"
                class="max-w-[200px] max-h-[200px] bg-main rounded-md"
                :placement="helpPosition">
                <template #trigger>
                    <n-icon size="20" class="text-main cursor-pointer ml-2">
                        <QuestionCircle20Filled />
                    </n-icon>
                </template>
                <span class="text-xs text-white/90" v-html="help"></span>
            </n-popover>
            <n-popover
                v-if="helpWarning"
                scrollable
                trigger="hover"
                class="max-w-[200px] max-h-[200px] bg-yellow-600 rounded-md"
                :placement="helpPosition">
                <template #trigger>
                    <n-icon size="20" class="text-yellow-600 cursor-pointer ml-1">
                        <Warning24Filled />
                    </n-icon>
                </template>
                <span class="text-xs text-white/90" v-html="helpWarning"></span>
            </n-popover>
        </div>

        <section
            class="flex flex-grow"
            :class="inputClasses">
            <slot name="beforeInput"></slot>
            <n-input
                clearable
                class="text-left rounded-md"
                show-password-on="mousedown"
                :size="size"
                :type="type"
                :value="value"
                :status="status"
                :disabled="disabled"
                :placeholder="placeholder"
                :allow-input="allowInput"
                :autofocus="autofocus"
                @update:value="$emit('update:value', $event)">
                <template #suffix><slot name="suffix"></slot></template>
            </n-input>
        </section>
    </div>
    <div v-if="status === 'error'" class="w-full mt-1 text-red-400 text-right text-[10px]">{{ msg }}</div>
    <n-divider v-if="divider" />
</label>
</template>

<script>
// vue
import { computed } from 'vue';

// naive-ui
import {
    NIcon,
    NInput,
    NDivider,
    NPopover } from 'naive-ui';

// icons
import { Warning24Filled, QuestionCircle20Filled } from '@vicons/fluent';

export default {
    name: 'rb-input',
    emits: ['update:value'],
    props: {
        label: {
            type: String,
            required: true,
        },
        labelPosition: {
            type: String,
            default: 'top',
            validator: prop => ['left', 'top'].includes(prop),
        },
        size: {
            type: String,
            default: 'medium',
            validator: prop => ['tiny', 'small', 'medium', 'large'].includes(prop),
        },
        help: {
            type: String,
        },
        helpPosition: {
            type: String,
            default: 'right',
        },
        value: {
            required: true,
        },
        placeholder: {
            type: String,
            default: null,
        },
        status: {
            type: String,
            default: undefined,
        },
        msg: {
            type: String,
        },
        type: {
            type: String,
            default: 'text',
            validator: prop => ['text', 'number', 'password', 'price', 'percent'].includes(prop),
        },
        divider: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        labelWidth: {
            type: [ String, Number ],
            default: 105,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        helpWarning: {
            type: String,
        },
    },
    components: {
        NIcon,
        NInput,
        NPopover,
        NDivider,
        Warning24Filled,
        QuestionCircle20Filled,
    },
    setup(props) {
        const allowInput = v => {
            if (props.type === 'number') {
                return !v || /^\d+$/.test(v);
            } else if (props.type === 'price') {
                return !v || /^[0-9]+(?:\.\d{0,2})?$/.test(v);
            } else if (props.type === 'percent') {
                // return !v || /^[0-9]+(?:\.\d*)?$/.test(v);
                return !v || /^[0-9]+(?:\.\d{0,8})?$/.test(v);
            } else if (props.type === 'pd_filter') {
                return !v || /^[0-9]+(?:\.\d{0,1})?$/.test(v);
            }
            
            return true;
        };

        const containerClasses = computed(() => {
            return [
                props.labelPosition === 'left' ? 'flex justify-between' : '',
                props.type === 'textarea' ? 'items-start' : 'items-center',
            ];
        });

        const labelClasses = computed(() => {
            return [
                // props.labelPosition === 'left' ? '' : '',
                props.type === 'textarea' ? 'mt-2' : '',
                props.help ? '' : 'pr-3',
            ];
        });

        const inputClasses = computed(() => {
            return props.labelPosition === 'left'
                ? ''
                : props.label ? 'mt-2' : '';
        });

        const titleClasses = computed(() => {
            return [
                props.status === 'error' ? 'text-red-400' : '',
                props.help
                    ? props.helpWarning
                        ? 'max-w-[calc(100%-40px)]'
                        : 'max-w-[calc(100%-20px)]'
                    : '',
            ];
        });

        return {
            allowInput,
            inputClasses,
            labelClasses,
            titleClasses,
            containerClasses,
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.label) {
    & b {
        color: #4949D9;
    }
}
</style>