// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';

// services
import ApiKeysService from'@services/apiKeysService';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useApiKeys } from '@store/apiKeys';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRoute, useRouter } from 'vue-router';

// naive-ui
import { useNotification } from 'naive-ui';

export default function (props, { emit }) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const apiKeys = useApiKeys();

    // router
    const route = useRoute();
    const router = useRouter();

    // ui
    const notification = useNotification();

    // i18n
    const { t } = useI18n();

    // vars
    const init = ref(false);
    const localLoading = ref(false);
    const collapseItem = ref();
    const mode = ref('create');
    const dataForm = reactive({});

    const mainButtonLabel = computed(() => props.data?.id ? apiKeys.localization.apikeys_update_button : apiKeys.localization.apikeys_title_button);
    const applyLabel = computed(() => props.data?.id ? apiKeys.localization.apikeys_update_button : apiKeys.localization.apikeys_add_button);
    const exchangeMetaInfo = computed(() => refs.exchanges.find(el => el.id === dataForm.exchange.value)?.meta);
    const exchangeMeta2Info = computed(() => refs.exchanges.find(el => el.id === dataForm.exchange.value)?.meta2);

    const exchangeOptions = computed(() => refs.exchanges.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    watch(() => route.params.id, v => {
        // if (v)
        // show.value = true;
    });

    watch(() => props.show, v => {
        if (v) {
            initModule();
        } else {
            emit('clear');
            mode.value = 'create';
        }
    });

    const apiKeyssRefs = async () => {
        if (apiKeys.refs) return;

        try {
            apiKeys.refs = ( await ApiKeysService.getRefs() ).data;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
    };

    const initModule = () => {
        const res = {
            name: props.data?.name || '',
            exchange: props.data?.exchange || -1,
            key: props.data?.key || '',
            sign: props.data?.sign ||  '',
            show_dashboard: props.data?.show_dashboard || props.showDashboard || false,
        };

        mode.value = props.data ? 'edit' : 'create';

        for (const [key, value] of Object.entries(res)) {
            if (key === 'name') {
                dataForm[key] = {
                    value,
                    title: apiKeys.localization['apikeys_name_f'],
                    placeholder: apiKeys.localization['apikeys_name_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'exchange') {
                dataForm[key] = {
                    value,
                    title: apiKeys.localization['apikeys_exchange_f'],
                    placeholder: apiKeys.localization['apikeys_exchange_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'key') {
                dataForm[key] = {
                    value,
                    title: apiKeys.localization['apikeys_key_f'],
                    placeholder: apiKeys.localization['apikeys_key_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'sign') {
                dataForm[key] = {
                    value,
                    title: apiKeys.localization['apikeys_sign_f'],
                    placeholder: apiKeys.localization['apikeys_sign_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'show_dashboard') {
                dataForm[key] = {
                    value,
                    title: apiKeys.localization['show_dashboard_label'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            };
        };
    };

    const hideShow = async ({ expanded }) => {
        if (expanded) {
            initModule();
        }
    };

    const exchange = exchange => refs.exchanges.find(el => el.id === exchange);

    const onCreateApiKey = async () => {
        localLoading.value = true;

        const formPath = mode.value === 'create' ? 'apikeys.addNew' : 'apikeys.update';

        const record = {
            exchange: dataForm.exchange.value,
            name: dataForm.name.value,
            key: dataForm.key.value,
            sign: dataForm.sign.value,
            show_dashboard: dataForm.show_dashboard.value,
            _formPath: formPath,
        };

        let result;

        try {
            result = mode.value === 'create'
                ? await ApiKeysService.addApiKeyToExchange(record.exchange, record)
                : await ApiKeysService.update({
                    id: props.data.id,
                    ...record,
                });

            if (!result.data.status) {
                if (result.data?.errors_form) {

                    for (let key in dataForm) {
                        const fields = result.data.errors_form[formPath].fields;
                        const el = Object.keys(fields).find(el => el === key);

                        if (el) {
                            dataForm[key].status = 'error';
                            dataForm[key].msg = fields[el].msg;
                        } else {
                            dataForm[key].status = 'success';
                            dataForm[key].msg = undefined;
                        }
                    }
                }
              
                if (result.data?.errors) {
                    result.data.errors.forEach(el => {
                        notification.error({
                            content: el.msg,
                            duration: 2500,
                            keepAliveOnHover: true,
                        });
                    });
                }
            } else {
                // show.value = false;
                emit('close');

                if (mode.value !== 'create') {
                    emit('updateApiKey', result.data.record);
                } else {
                    emit('readyKey', result.data.records[0]);
                }
                // clean apiKeyModel
                for (let key in dataForm)
                    dataForm[key].value = '';

                // show messages
                result.postMessages.forEach(el => {
                    notification[el.success ? 'success' : 'error']({
                        content: el.msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });

                emit('apiKeysGet');
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        }

        localLoading.value = false;
    };

    onMounted(async () => {
        await apiKeyssRefs();
        init.value = true;
    });

    return {
        gl,
        refs,
        mode,
        init,
        apiKeys,
        dataForm,
        applyLabel,
        collapseItem,
        localLoading,
        exchangeOptions,
        mainButtonLabel,
        exchangeMetaInfo,
        exchangeMeta2Info,
        hideShow,
        exchange,
        onCreateApiKey,
    };
}