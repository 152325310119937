<template>
<!-- <apikeys-create
    v-if="init"
    :data="apiKeyInfo"
    @apiKeysGet="apiKeysGet"
    @clear="apiKeyInfo = null"
    @updateApiKey="updateApiKey" /> -->
<n-card
    size="small"
    :bordered="false"
    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
    :segmented="{
      content: true,
      footer: 'soft'
    }">
    <template #default>
        <div class="border border-dashed border-gray-600 rounded-md flex flex-col items-center py-6">
            <ApiKeysIcon class="w-24 fill-current" />
            <n-button
                strong
                class="mt-4 text-white/90 rounded-md"
                :color="gl.mainColor"
                @click="walletApiKeyDialog = true">
                + {{ apiKeys.localization?.apikeys_title_button }}
            </n-button>
        </div>
    </template>
</n-card>

<super-table
    v-if="init"
    showSearch
    :filters="filters"
    :refFilters="refFilters"
    :actions="actions"
    :pager="apiKeys.apiKeys.pager"
    :columns="columns"
    :records="records"
    :filtersChanged="filtersChanged"
    type="apiKeys"
    columnsStorageName="__columns_api_keys"
    @getData="apiKeysGet"
    @sortColumn="sortColumn"
    @doSomething="doSomething"
    @reset-filters="resetFilters"
    @applyFilters="changeFilter">
    <template #tableTitle>
        <div class="text-base text-left mb-2 text-gray-600 dark:text-white/75">
            {{ apiKeys.localization['apikeys_all_keys_title'] }}
        </div>
    </template>
    <template #actionButtons>
        <section v-if="apiKeys.records.length" class="flex">
            <n-button
                class="text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="apiKeysDelete(true)">
                {{ apiKeys.localization['apikeys_all_keys_table_mass_delete_button'] }}
            </n-button>
        </section>
    </template>
</super-table>
<rb-api-key-cr
    v-if="init"
    :api-key-id="apiKeyId"
    :show="walletApiKeyDialog"
    @update:show="triggerModal($event)"
    @updateApiKey="updateApiKey"
    @addNewApiKey="addNewApiKey($event)" />
</template>

<script>
// general
import general from './general';

// components
import SuperTable from '@components/super-table/desktop.vue';
import ApikeysCreate from '@components/apikeys-create/index.vue';
import RbApiKeyCr from '@components/rb-api-key-cr';
import ApiKeysIcon from '@components/icons/api-keys.vue';

// UI
import {
    NCard,
    NTabs,
    NButton,
    NTabPane } from 'naive-ui';

export default {
    name: 'ApiKeys',
    components: {
        NCard,
        NTabs,
        NButton,
        NTabPane,
        RbApiKeyCr,
        SuperTable,
        ApiKeysIcon,
        ApikeysCreate,
    },
    setup(props, context) {
        
        return {
            ...general(arguments),
        };
    },
};
</script>