<template>
<n-radio-group
    :value="value"
    :size="size"
    :loading="loading"
    :disabled="disabled"
    class="bg-white dark:bg-inputBackground rounded-md flex items-center px-[4px]"
    @update:value="$emit('update:value', $event)">
    <n-radio-button
        v-for="option in localOptions"
        class="border-0 rounded hover:border-0"
        :key="option.value"
        :value="option.value"
        :label="option.label" />
</n-radio-group>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NRadioGroup,
    NRadioButton } from 'naive-ui';

export default {
    name: 'rb-radio-group-mobile',
    props: {
        value: {
            type: [String, Number],
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        size: {
            type: String,
            default: 'medium',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NRadioGroup,
        NRadioButton,
    },
    setup(props, context) {
    
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.n-radio-group__splitor) {
    display: none;
}

:deep(.n-radio-button__state-border) {
    box-shadow: none !important;
}

:deep(.n-radio-button) {
    height: 80% !important;
    display: flex;
    align-items: center;
    margin-top: 0 !important;

    & .n-radio__label {
        margin-top: -1px;
        line-height: normal !important;
    }
}
</style>