<template>
  <mobile v-if="gl.isMobile">
    <template #beforeInput><slot name="beforeInput"></slot></template>
    <template #suffix><slot name="suffix"></slot></template>
  </mobile>
  <desktop v-else>
    <template #beforeInput><slot name="beforeInput"></slot></template>
    <template #suffix><slot name="suffix"></slot></template>
  </desktop>
</template>

<script>
// store
import { useGl } from '@store/ts/gl';

// components
import Desktop from './desktop.vue';
import Mobile from './mobile.vue';

export default {
    name: 'rb-api-key-cr',
    components: {
        Mobile,
        Desktop,
    },
    setup() {
        const gl = useGl();
    
        return {
            gl,
        };
    },
};
</script>
