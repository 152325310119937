// vue
import { ref, computed, watch, onMounted } from 'vue';

// services
import ToolsService from '@services/tools';

// i18n
import { useI18n } from 'vue-i18n';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useTools } from '@store/tools';

// router
import { useRoute, useRouter } from 'vue-router';

// UI
import {useMessage } from 'naive-ui';

export default function (props, context) {
    const route = useRoute();
    const router = useRouter();

    const gl = useGl();
    const refs = useRefs();
    const tools = useTools();

    // ui
    

    // i18n
    const { t } = useI18n();

    // vars
    const init = ref(false);
    const loading = ref(false);

    const currentTab = computed(() => {
        if (route.name.includes('tools.stats')) {
            return 'tools'; 
        } else if (route.name.includes('tools.indicators')) {
            return 'volatility-indicators'; 
        } else if (route.name.includes('tools.va')) {
            return 'volatility-analyzer'; 
        } else if (route.name.includes('tools.presets')) {
            return 'presets'; 
        }

        return 'tools';
    });

    watch(() => refs.lang, async () => {
        await getData();
    });

    const paths = {
        'tools': '',
        'volatility-indicators': '/volatility-indicators',
        'volatility-analyzer': '/volatility-analyzer',
        'presets': '/presets',
    };

    const tabs = computed(() => !init.value
        ? []
        : [
            {
                name: 'tools',
                title: tools.localization['tools/stats/tab/title'],
            }, {
                name: 'volatility-indicators',
                title: refs.localization.sidebar['volatility_indicators'],
            }, {
                name: 'volatility-analyzer',
                title: tools.localization['tools_volatility_analyzer_title'],
            }, {
                name: 'presets',
                title: tools.localization?.tools_presets_title || 'Шаблоны',
            },
        ]);
        
    const changeTab = tab => {
        router.push({ path: `/tools${paths[tab]}` });
        currentTab.value = tab;
    };

    const getRefs = async (showLoading = true) => {
        if (showLoading)
            loading.value = true;

        try {
            tools.refs = ( await ToolsService.getRefs() ).data;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
            
        if (showLoading)
            loading.value = false;
    };

    const getData = async () => {
        loading.value = true;

        await Promise.allSettled([
            getRefs(false),
        ]);

        loading.value = false;
    };

    onMounted(async () => {
        await getData();

        init.value = true;
    });

    return {
        tabs,
        init,
        refs,
        loading,
        currentTab,
        changeTab,
    };
}