<template>
<section class="w-full fixed top-0 left-0 z-[100] flex justify-between items-center pt-4 pb-2 px-3 bg-gray-100 dark:bg-darkbg">
    <div class="flex items-center">
        <img class="" src="@assets/img/mobile-logo.svg" alt="logo">
        <n-button
            size="tiny"
            class="ml-2 rounded-2xl"
            @click="goOfficeV1">
            <div class="text-xs font-semibold">office V1</div>
        </n-button>
    </div>
   

    <div class="flex justify-end">
        <div @click="changeTheme" class="mr-4 ml-2 text-[10px]">changeTheme</div>
        <n-dropdown
            :options="options"
            placement="bottom-start"
            trigger="click"
            size="small"
            @select="changeLanguage">
            <n-button
                round
                size="tiny"
                tag="span"
                color="#fff"
                class="mt-1"
                icon-placement="right">
                <n-icon>
                    <component :is="icons[currrentLang]"></component>
                </n-icon>
                <div class="text-[10px] font-semibold uppercase ml-1 text-main">{{ currrentLang }}</div>
            </n-button>
        </n-dropdown>
        <n-dropdown
            show-arrow
            size="small"
            trigger="click"
            placement="bottom-start"
            :options="qna"
            @select="optionFn">
            <div class="flex justify-center items-center ml-4">
                <n-icon size="30" class="text-gray-600 dark:text-white/75">
                    <ChatBubblesQuestion16Regular />
                </n-icon>
            </div>
        </n-dropdown>

        <div class="ml-4 flex justify-center items-center" @click="showNotifications = true">
            <n-badge :value="gl.notifications.length" :offset="[-5, 5]">
                <n-icon size="30" class="text-gray-600 dark:text-white/75">
                    <Alert16Regular />
                </n-icon>
            </n-badge>
        </div>

        <div
            class="ml-4 flex justify-center items-center "
            @click="router.push({ name: 'profile', query: { tab: 'account_info' } })">
            <n-icon size="30" class="text-gray-600 dark:text-white/75">
                <Person16Regular />
            </n-icon>
        </div>
    </div>
    <n-drawer
        v-model:show="showNotifications"
        width="100%"
        class="bg-gray-100 dark:bg-darkbg">
        <n-drawer-content body-content-style="padding: 0 12px;">
            <div class="flex flex-col h-full">
                <div class="relative w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-4">
                    <n-icon
                        size="20"
                        class="text-gray-600 dark:text-white/75 absolute top-1/2 left-0 -translate-y-1/2"
                        @click="showNotifications = false">
                        <ArrowLeft12Regular />
                    </n-icon>
                    {{ t('notifications') }}
                </div>
                <div v-if="gl.notifications.length" class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
                    <n-button
                        secondary
                        type="default"
                        class="w-full rounded-md"
                        @click="onDeleteNotifications(null)">
                        {{ refs.localization.header.mark_all_as_read }}
                    </n-button>

                    <template v-for="item in gl.notifications" :key="item.id">
                      <n-card
                          size="small"
                          class="mt-2 overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md"
                          :bordered="false"
                          :segmented="{
                            content: true,
                            footer: 'soft'
                          }"
                          @click="onDescribeInternal(item)">
                          <div class="flex justify-between items-center">
                              <div class="text-md text-gray-600 dark:text-white/75 w-10/12">{{ item.title }}</div>
                              <n-button
                                tertiary circle type="error"
                                size="tiny"
                                  :color="gl.mainColor"
                                  @click.prevent.stop="onDeleteNotifications(item.id)">
                                  <template #icon>
                                      <n-icon class="text-red-500" size="10"><Dismiss16Filled /></n-icon>
                                  </template>
                              </n-button>
                          </div>
                          <n-tag class="no-underline mt-2" :bordered="false" type="warning" size="tiny">{{ item.timestamp }}</n-tag>
                      </n-card>
                    </template>
                </div>
                <template v-else>
                    <div class="text-base">{{ t('emptyNotifications') }}</div>
                </template>
            </div>
        </n-drawer-content>
        <n-drawer v-model:show="showCurrentNotification" width="100%" class="bg-gray-100 dark:bg-darkbg">
            <n-drawer-content class="relative">
                <div class="relative text-sm text-center text-gray-600 dark:text-white/75 mb-4">
                    <n-icon
                        size="20"
                        class="text-gray-600 dark:text-white/75 absolute top-1/2 left-0 -translate-y-1/2"
                        @click="showCurrentNotification = false">
                        <ArrowLeft12Regular />
                    </n-icon>
                    &nbsp;
                </div>
                <rb-loader v-if="gettingNotification" class="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2" />
                <div v-else v-html="currentNotification.content"></div>
            </n-drawer-content>
        </n-drawer>
    </n-drawer>
    <base-drawer-mobile
        :label="refs.localization.privateMessage.dialogHeader"
        v-model:show="showPrivateMessage">
        <template #default>
            <template v-for="(field, i) in dataFormPrivateMessage" :key="i">
                <rb-input
                    class="mb-4"
                    :label="field.title"
                    :type="field.type"
                    :status="field.status === 'error' ? 'error' : undefined"
                    :msg="field.msg"
                    v-model:value="field.value"
                    @update:value="field.status = null, field.msg = null" />
            </template>

            <div class="flex justify-center">
                <vue-recaptcha
                    ref="recaptcha"
                    sitekey="6LeQDWYaAAAAAPUoBoIEo2blRv4APQ5BDTYmf3MC"
                    size="normal" 
                    theme="light"
                    :hl="env.language"
                    @verify="recaptchaKey = $event">
                </vue-recaptcha>
            </div>
        </template>
        <template #footer>
            <n-button
                class="rounded-md text-white/90 w-full"
                :color="gl.mainColor"
                :loading="privateMessageLoading"
                :disabled="privateMessageLoading"
                @click="onPrivateMessageSubmit">
                {{ refs.localization.privateMessage.sendBtn }}
            </n-button>
        </template>
    </base-drawer-mobile>
</section>
</template>

<script>
// vue
import { h, ref, computed, watch, reactive } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useUser } from '@store/user';
import { useRefs } from '@store/ts/refs';

// router
import { useRouter } from 'vue-router';

// components
import vueRecaptcha from 'vue3-recaptcha2';
import RbInput from '@components/rb-input/mobile.vue';

// icons
import ruIcon from '@components/lang-icons/ru.vue';
import enIcon from '@components/lang-icons/en.vue';
import esIcon from '@components/lang-icons/es.vue';

// i18n
import { useI18n } from 'vue-i18n';

// services
import Api from '@services/api';
import RealmService from '@services/realmService';
import ProfileService from '@services/profileService';
import LocalStorage from '@services/ts/localStorage';

// shared
import util from '@shared/util';

// icons
import {
    Alert16Regular,
    Person16Regular,
    Dismiss16Filled,
    ArrowLeft12Regular,
    ChatBubblesQuestion16Regular } from '@vicons/fluent';

// naive-ui
import {
    NTag,
    NCard,
    NIcon,
    NBadge,
    NDrawer,
    NButton,
    NDivider,
    NPopover,
    NDropdown,
    NSkeleton,
    useMessage,
    useNotification,
    NDrawerContent } from 'naive-ui';

export default {
    name: 'mobile-header',
    components: {
        NTag,
        NIcon,
        NCard,
        NBadge,
        NDrawer,
        RbInput,
        NButton,
        NDropdown,
        vueRecaptcha,
        NDrawerContent,
        Alert16Regular,
        Dismiss16Filled,
        Person16Regular,
        ArrowLeft12Regular,
        ChatBubblesQuestion16Regular,
    },
    setup() {
        // store
        const gl = useGl();
        const env = useEnv();
        const user = useUser();
        const refs = useRefs();

        // naive-ui
        
        const notification = useNotification();

        // router
        const router = useRouter();

        // i18n
        const { t } = useI18n();

        // vars
        const recaptcha = ref();
        const recaptchaKey = ref();
        const currentNotification = ref();
        const currrentLang = ref(refs.lang);
        const gettingNotification = ref(false);
        const showNotifications = ref(false);
        const showCurrentNotification = ref(false);
        const privateMessageLoading = ref(false);
        const showPrivateMessage = ref(false);

        const renderIcon = (icon) => {
            return () => {
                return h(NIcon, null, {
                    default: () => h(icon),
                });
            };
        };

        const icons = computed(() => {
            return  {
                'ru': ruIcon,
                'en': enIcon,
                'es': esIcon,
            };
        });

        const options = computed(() => {
            return refs.langs.map(el => ({
                label: el.title,
                key: el.id,
                ...el,
                icon: renderIcon(icons.value[el.id]),
            }));
        });

        const qna = computed(() => ([
            {
                label: refs.header.faq,
                key: 1,
                fn() {
                    window.open(
                        refs.lang === 'en'
                            ? 'https://desk.revenuebot.io/en/knowledgebase'
                            : 'https://desk.revenuebot.io/ru/%D0%B1%D0%B0%D0%B7%D0%B0-%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B9',
                        '_blank');
                },
            }, {
                label: refs.header.blog,
                key: 2,
                fn() {
                    window.open(
                        refs.lang === 'en'
                            ? 'https://blog.revenuebot.io/'
                            : 'https://blog.revenuebot.io/ru/',
                        '_blank');
                },
            }, {
                label: refs.header.referral_program,
                key: 3,
                fn() {
                    router.push({ name: 'profile', query: { tab: 'referral_info' }});
                },
            }, {
                label: refs.header.ticket_supper,
                key: 4,
                fn() {
                    window.open(
                        refs.lang === 'en'
                            ? 'https://desk.revenuebot.io/en/tickets/create/step1'
                            : 'https://desk.revenuebot.io/ru/tickets/create/step1',
                        '_blank');
                },
            }, {
                label: '@revenuebot_user',
                key: 5,
                fn() {
                    window.open(
                        refs.lang === 'ru'
                            ? 'https://t.me/revenuebot_user'
                            : 'https://t.me/revenuebot_users_en',
                        '_blank');
                },
            }, {
                label: refs.privateMessage.menuTitle,
                key: 6,
                fn() {
                    showPrivateMessage.value = true;
                },
            }, {
                label: refs.header?.free_consultations || 'Бесплатные консультации',
                key: 7,
                fn() {
                    window.open('https://appt.link/consultation_from_support/revenuebot', '_blank');
                },
            },
        ]));

        const dataFormPrivateMessage = reactive({
            to: {
                value: '',
                type: 'text',
                status: undefined,
                msg: undefined,
                title: refs.localization.privateMessage.recipientId,
            },
            subject: {
                value: '',
                type: 'text',
                status: undefined,
                msg: undefined,
                title: refs.localization.privateMessage.subject,
            },
            message: {
                value: '',
                type: 'textarea',
                status: undefined,
                msg: undefined,
                title: refs.localization.privateMessage.message,
            },
        });

        const onDeleteNotifications = async id => {
            gl.showLoading = true;

            let ids = id ? [id] : gl.notifications.map(({ id }) => id);

            try {
                const data = await ProfileService.markNotificationsAsRead({ ids });

                if (!data?.data && data.msg) {
                    gl.showNotification({
                        type: 'error',
                        msg: data.msg,
                    });
                } else {
                    gl.notifications = gl.notifications.filter(el => !ids.find(id => id === el.id));

                    if (ids.length > 1)
                        showNotifications.value = false;
                };
            } catch {
                gl.showNotification({
                    type: 'error',
                    msg: t('errorMessage'),
                });
            }

            gl.showLoading = false;
        };

        const onDescribeInternal = async n => {
            gettingNotification.value = true;
            showCurrentNotification.value = true;

            if (Array.isArray(n.realm)) {
                try {
                    const prepare = await RealmService.describeInternal(n.realm[0]);
                    
                    if (prepare.data?.errors?.length) {
                        prepare.data.errors.forEach(({ msg }) => {
                            gl.showNotification({
                                type: 'error',
                                msg,
                            });
                        });
                    } else {
                        gl.notifications = gl.notifications.filter(({ id }) => id !== n.id);
                        currentNotification.value = prepare.data.alert;
                    };
                } catch {
                    gl.showNotification({
                        type: 'error',
                        msg: t('errorMessage'),
                    });
                }
            } else if (n.url) {
                window.location.href = n.url;
            }

            gettingNotification.value = false;
        };

        ////
        const changeTheme = () => {
            if (!~[...document.documentElement.classList].indexOf('dark')) {
                LocalStorage.setItem('darkTheme', true);
                gl.darkTheme = true;
            } else {
                LocalStorage.setItem('darkTheme', false);
                gl.darkTheme = false;
            }

            document.documentElement.classList.toggle('dark');
        };
        ////

        const changeLanguage = async key => {
            gl.showLoading = true;
            
            currrentLang.value = key;

            await Api.changeLang({ lang: key });

            location.reload();

            // const result = await Promise.allSettled([
            //     Api.getRefs(user),
            //     Api.getEnv(user),
            // ]);

            // refs.refs = result[0].value.data;
            // env.data = result[1].value.data;
                
            gl.showLoading = false;
        };

        const optionFn = (_, item) => {
            item.fn();
        };

        const goOfficeV1 = () => {
            let url = new URL(window.location.href);
            window.open(url.origin + '/officev1/', '_blank');
        };

        const onPrivateMessageSubmit = async () => {
            privateMessageLoading.value = true;
            const msg = {};
            
            Object.entries(dataFormPrivateMessage).forEach(([ key, value ]) => {
                msg[key] = value.value;
            });

            msg.from = env.ID;
            msg['_formPath'] = 'pmsg';
            msg['g-recaptcha-response'] = recaptchaKey.value;
            
            try {
                const prepare = await ProfileService.privateMessage(msg);

                if (!prepare.data.status) {
                    if (prepare.data?.errors_form && Object.keys(prepare.data?.errors_form).length) {

                        for (let key in dataFormPrivateMessage) {
                            const fields = prepare.data.errors_form.pmsg.fields;
                            const el = Object.keys(fields).find(el => el === key);

                            if (el) {
                                dataFormPrivateMessage[key].status = 'error';
                                dataFormPrivateMessage[key].msg = fields[el].msg;
                            } else {
                                dataFormPrivateMessage[key].status = 'success';
                                dataFormPrivateMessage[key].msg = undefined;
                            }
                        }
                    }
                } else {
                    showPrivateMessage.value = false;
                    // clean dataFormPrivateMessage
                    for (let key in dataFormPrivateMessage)
                        dataFormPrivateMessage[key].value = '';
                }

                if (prepare.postMessages)
                    prepare.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };

            recaptcha.value.reset();

            privateMessageLoading.value = false;
        };

        return {
            gl,
            env,
            qna,
            refs,
            icons,
            router,
            options,
            recaptcha,
            recaptchaKey,
            currentNotification,
            currrentLang,
            showNotifications,
            showPrivateMessage,
            gettingNotification,
            dataFormPrivateMessage,
            showCurrentNotification,
            privateMessageLoading,
            t,
            optionFn,
            goOfficeV1,
            changeTheme,
            changeLanguage,
            onDescribeInternal,
            onDeleteNotifications,
            onPrivateMessageSubmit,
        };
    },
};
</script>