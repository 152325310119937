<template>
<base-modal-desktop
    :label="marketplace.localization['marketplace/buy/review/title']"
    :show="show"
    :lightDark="false"
    width="1000px"
    @update:show="router.go(-1)"
    @after-leave="init = false, newBotId = null">
    <template #default>
        <div v-if="!init" class="flex h-full justify-center">
            <n-spin size="small" />
        </div>

        <template v-else>
            <template v-if="!newBotId && mode !== 'dashboard'">
                <n-alert
                    class="bg-[#eaf0fe] dark:bg-blue-900 dark:bg-opacity-20 rounded-lg" type="info">
                    <div class="text-xs mt-[2px] text-gray-600 dark:text-white/75 notes" v-html="confIntro"></div>
                </n-alert>

                <super-table
                    :showHeader="false"
                    :showFooter="false"
                    :columns="columns"
                    :records="records">
                </super-table>
            </template>

            <n-alert v-else-if="mode !== 'dashboard'" class="mt-2 rounded-lg" type="success">
                <div class="font-bold text-xs notes">{{ successMessage }}</div>
            </n-alert>

            <n-alert v-if="bot.data.general.notes && !configBought && mode !== 'dashboard'" class="rounded-lg mt-4" type="warning">
                <n-collapse arrow-placement="right">
                    <n-collapse-item>
                        <template #header>
                            <div class="text-xs">{{ marketplace.localization['marketplace/buy/review/postinfo/has_notes'] }}</div>
                        </template>
                        <div
                            v-if="bot.data.general.notes"
                            class="text-xs notes"
                            v-html="bot.data.general.notes">
                        </div>
                    </n-collapse-item>
                </n-collapse>
            </n-alert>

            <template v-if="!newBotId">
                <n-alert v-if="exchangeMeta3Info && mode !== 'dashboard'" class="rounded-lg mt-4" type="warning">
                    <div class="text-xs notes" v-html="exchangeMeta3Info"></div>
                </n-alert>

                <n-alert v-if="mode !== 'dashboard'" class="rounded-lg mt-4" type="warning">
                    <n-collapse arrow-placement="right">
                        <n-collapse-item>
                            <template #header>
                                <div class="text-xs">{{ marketplace.localization['marketplace/buy/review/api_key__wallet/advice/title'] }}</div>
                            </template>
                            <div
                                v-if="marketplace.localization['marketplace/buy/review/api_key__wallet/advice/body']"
                                class="text-xs notes"
                                v-html="marketplace.localization['marketplace/buy/review/api_key__wallet/advice/body']">
                            </div>
                        </n-collapse-item>
                    </n-collapse>
                </n-alert>

                <n-card
                    size="small"
                    :bordered="false"
                    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4"
                    :segmented="{
                        content: true,
                        footer: 'soft'
                    }">
                    <template #default>
                        <rb-input
                            :label="buyBotModel.name.title"
                            :placeholder="buyBotModel.name.placeholder"
                            :status="buyBotModel.name.status === 'error' ? 'error' : undefined"
                            :msg="buyBotModel.name.msg"
                            v-model:value="buyBotModel.name.value"
                            @update:value="buyBotModel.name.status = undefined, buyBotModel.name.msg = undefined" />
                    </template>
                </n-card>

                <section class="flex mt-4">
                    <div class="w-6/12 pr-2">
                        <n-card
                            size="small"
                            :bordered="false"
                            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
                            :segmented="{
                                content: true,
                                footer: 'soft'
                            }">
                            <template #default>
                                <div class="flex justify-between">
                                    <div class="flex flex-wrap items-center">
                                        <div
                                            class="text-md text-gray-600 dark:text-white/75">
                                            {{ marketplace.localization['marketplace/buy/review/api_key/header'] }}
                                        </div>
                                    </div>

                                    <n-radio-group class="w-5/12 flex justify-end" size="small" v-model:value="apiOptions">
                                        <n-radio-button
                                            v-for="option in options"
                                            :key="option.value"
                                            :value="option.value"
                                            :label="option.label"
                                        />
                                    </n-radio-group>
                                </div>

                                <div class="mt-6">
                                    <template v-if="apiOptions === 'select'">
                                        <rb-select
                                            v-if="apiKeys.length"
                                            :label="marketplace.localization['marketplace/buy/review/api_key/label']"
                                            :options="apiKeys"
                                            v-model:value="apiKey" />
                                        <n-alert
                                            v-else class="w-full mt-4 rounded-lg" type="error">
                                            <div class="text-xs noter">{{ marketplace.localization['marketplace/buy/review/api_key/no_keys_for_exchange'] }}</div>
                                        </n-alert>
                                        <n-alert
                                            v-if="marketplace.localization['marketplace/buy/review/api_key/select_resume']"
                                            class="mt-4 rounded-lg w-full" type="info">
                                            <div class="notes text-xs" v-html="marketplace.localization['marketplace/buy/review/api_key/select_resume']"></div>
                                        </n-alert>
                                    </template>
                                    <template v-else>
                                        <template
                                            v-for="(apiKey, key) in apiKeyModel"
                                            :key="key">
                                            <rb-input
                                                class="mt-4"
                                                :label="apiKey.title"
                                                :status="apiKey.status === 'error' ? 'error' : undefined"
                                                :msg="apiKey.msg"
                                                :placeholder="apiKey.placeholder"
                                                v-model:value="apiKey.value"
                                                @update:value="apiKey.status = null, apiKey.msg = ''" />
                                        </template>
                                        
                                        <div class="flex flex-wrap justify-end mt-4">
                                            <n-button
                                                size="medium"
                                                class="mr-2 rounded-md text-white/90"
                                                :color="gl.mainColor"
                                                :loading="addApiLoading"
                                                :disabled="createApiDisable || addApiLoading"
                                                @click="onCreateApiKey">
                                                + {{ marketplace.localization['marketplace/buy/review/api_key/create_btn'] }}
                                            </n-button>

                                            <n-alert
                                                v-if="marketplace.localization['marketplace/buy/review/api_key/create_resume']"
                                                class="mt-4 rounded-lg" type="info">
                                                <div class="notes text-xs" v-html="marketplace.localization['marketplace/buy/review/api_key/create_resume']"></div>
                                            </n-alert>

                                            <n-alert v-if="exchangeMeta2Info" class="mt-4 rounded-lg" type="warning">
                                                <div class="notes text-xs" v-html="exchangeMeta2Info"></div>
                                            </n-alert>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </n-card>
                    </div>
                    <div v-if="apiKey && apiOptions === 'select'" class="w-6/12 pl-2">
                        <n-card
                            size="small"
                            :bordered="false"
                            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
                            :segmented="{
                                content: true,
                                footer: 'soft'
                            }">
                            <div class="flex justify-between">
                                <div class="flex flex-wrap items-center">
                                    <div
                                        class="text-md text-gray-600 dark:text-white/75">
                                        {{ marketplace.localization['marketplace/buy/review/wallet/title'] }}
                                    </div>
                                </div>

                                <n-radio-group class="w-5/12 flex justify-end" size="small" v-model:value="walletOptions">
                                    <n-radio-button
                                        v-for="option in options"
                                        :key="option.value"
                                        :value="option.value"
                                        :label="option.label"
                                    />
                                </n-radio-group>
                            </div>

                            <div class="mt-6">
                                <template v-if="walletOptions === 'select'">
                                    <rb-wallet-select
                                        v-model:value="wallet"
                                        class="flex-none w-full"
                                        :label="marketplace.localization['marketplace/buy/review/wallet/label']"
                                        :items="pairWalletsRefs"/>

                                    <div class="flex flex-row flex-wrap justify-end mt-4">
                                        <n-button
                                            class="rounded-md text-white/90"
                                            :color="gl.mainColor"
                                            :disabled="!isWalletSelected || checkBalanceLoading"
                                            :loading="checkBalanceLoading"
                                            @click="onCheckBalance">
                                            {{ marketplace.localization['marketplace/buy/review/wallet/check_balance'] }}
                                        </n-button>
                                        
                                        <n-card
                                            v-if="symbolBalance || symbolTotalBalance"
                                            size="small"
                                            :bordered="false"
                                            class="overflow-hidden shadow bg-main rounded-lg mt-4"
                                            :segmented="{
                                                content: true,
                                                footer: 'soft'
                                            }">
                                            <div class="w-full flex flex-row justify-between items-center text-white/75">
                                                <div v-if="symbolBalance" class="text-sm">
                                                    {{ marketplace.localization['marketplace/buy/review/wallet/balance__available'] }}
                                                    <span class="font-semibold text-base">{{ symbolBalance }}</span>
                                                </div>
                                                <n-divider class="text-white/75 h-14" vertical />
                                                <div v-if="symbolTotalBalance" class="text-sm ml-4">
                                                    {{ marketplace.localization['marketplace/buy/review/wallet/balance__with_orders'] }}
                                                    <span class="font-semibold text-base">{{ symbolTotalBalance }}</span>
                                                </div>
                                            </div>
                                        </n-card>

                                        <n-alert
                                            v-if="marketplace.localization['marketplace/buy/review/wallet/select_resume']"
                                            class="mt-4 rounded-lg w-full" type="info">
                                            <div class="notes text-xs" v-html="marketplace.localization['marketplace/buy/review/wallet/select_resume']"></div>
                                        </n-alert>

                                        <n-alert
                                            v-if="marketplace.localization['marketplace/buy/review/wallet/depo_info']"
                                            class="mt-4 rounded-lg w-full" type="warning">
                                            <div class="notes text-xs" v-html="marketplace.localization['marketplace/buy/review/wallet/depo_info']"></div>
                                        </n-alert>
                                    </div>
                                </template>
                                <template v-else>
                                    <div
                                        v-for="(field, key) in walletModel"
                                        class="flex flex-row flex-wrap items-end mt-4"
                                        :key="key">
                                        <rb-input
                                            v-model:value="field.value"
                                            :class="field.class"
                                            :status="field.status === 'error' ? 'error' : undefined"
                                            :msg="field.msg"
                                            :label="field.title"
                                            :placeholder="field.placeholder" />
                                        <div class="flex items-center ml-4" v-if="field.currency">
                                            <rb-coin :coin="currency(field.currency)" />
                                            <span class="text-main text-base font-semibold uppercase ml-2">{{ field.currency }}</span>
                                        </div>
                                    </div>

                                    <div class="flex flex-wrap justify-end mt-4">
                                        <n-button
                                            class="rounded-md text-white/90"
                                            :color="gl.mainColor"
                                            :loading="loadingCreateWallet"
                                            :disabled="canCreateWallet || loadingCreateWallet"
                                            @click="onCreateWallet">
                                            + {{ marketplace.localization['marketplace/buy/review/wallet/create_btn'] }}
                                        </n-button>

                                        <n-alert
                                            v-if="marketplace.localization['marketplace/buy/review/wallet/create_resume']"
                                            class="mt-4 text-left rounded-lg" type="info">
                                            <div class="notes text-xs" v-html="marketplace.localization['marketplace/buy/review/wallet/create_resume']"></div>
                                        </n-alert>
                                    </div>
                                </template>
                            </div>
                        </n-card>
                    </div>
                </section>
            </template>
            
            <template v-if="newBotId">
                <n-alert v-if="notes" class="rounded-lg mt-4" type="warning">
                    <n-collapse arrow-placement="right">
                        <n-collapse-item>
                            <template #header>
                                <div class="text-xs">{{ marketplace.localization['marketplace/buy/review/postinfo/has_notes'] }}</div>
                            </template>
                            <div
                                v-if="bot.data.general.notes"
                                class="notes text-xs"
                                v-html="bot.data.general.notes">
                            </div>
                        </n-collapse-item>
                    </n-collapse>
                </n-alert>

                <n-alert v-if="ownerContact" class="rounded-lg mt-4" type="warning">
                    <n-collapse arrow-placement="right">
                        <n-collapse-item>
                            <template #header>
                                <div class="text-xs">{{ marketplace.localization['marketplace/sell/review/owner/label'] }}</div>
                            </template>
                            <div
                                class="notes text-xs"
                                v-html="ownerContact">
                            </div>
                        </n-collapse-item>
                    </n-collapse>
                </n-alert>
                <n-card
                    size="small"
                    :bordered="false"
                    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4"
                    :segmented="{
                        content: true,
                        footer: 'soft'
                    }">
                    <div
                        v-for="(item, i) in botActionLinks"
                        :key="i"
                        class="pt-2">
                        <div v-if="item.routerLink" class="flex items-center text-md mb-2">
                            <router-link :to="item.path" class="text-main">{{ item.link }} &nbsp;</router-link>
                            - {{ item.text }}
                        </div>
                        <div v-else class="text-md mb-2 text-gray-600 dark:text-white/75" v-html="item.text"></div>
                    </div>
                </n-card>
            </template>
        </template>
    </template>
    <template #footer>
        <div v-if="!newBotId && init" class="flex justify-end">
            <n-button
                strong
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                :loading="loadingBuyBot"
                :disabled="!canBuyConfiguration || loadingBuyBot"
                @click="onBuyConfiguration">
                <template v-if="mode === 'marketplace'">
                    <span>{{ marketplace.localization['marketplace/buy/review/buttons/buy'] }}</span>
                    <small class="pl-1">( {{ bot.data.general.config_price }}<b>{{ env.profitSymbol }}</b> )</small>
                </template>
                <template v-else-if="mode === 'dashboard'">
                    <span>{{ marketplace.localization['marketplace/buy/review/buttons/copy'] }}</span>
                </template>
            </n-button>
        </div>
    </template>
</base-modal-desktop>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NIcon,
    NCard,
    NSpin,
    NTable,
    NAlert,
    NInput,
    NSelect,
    NButton,
    NDivider,
    NCollapse,
    NRadioGroup,
    NRadioButton,
    NCollapseItem } from 'naive-ui';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbDepo from '@components/rb-depo/index.vue';
import RbWalletSelect from '@components/rb-wallet-select';
import SuperTable from '@components/super-table/desktop.vue';

// icons
import { Warning24Filled } from '@vicons/fluent';

export default {
    name: 'BotReviews',
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        withFilters: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: 'marketplace',
        },
    },
    components: {
        NIcon,
        NCard,
        NSpin,
        NTable,
        RbDepo,
        RbCoin,
        NAlert,
        NInput,
        RbInput,
        NButton,
        NSelect,
        NDivider,
        RbSelect,
        NCollapse,
        SuperTable,
        NRadioGroup,
        NRadioButton,
        NCollapseItem,
        RbWalletSelect,
        Warning24Filled,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(a) {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

:deep(.conf-intro) {
    p:not(:first-child) {
        margin-top: 12px;
    }

    b {
        color: #4949D9;
        text-transform: uppercase;
    }

    a {
        color: #4949D9;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

:deep(a) {
    color: #4949D9;
}
</style>