<template>
<section>
    <n-card
        size="small"
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
        :segmented="{
            content: true,
            footer: 'soft'
        }">
        <template #header>
            <Progress v-if="autoUpdate" class="mb-4" ref="progress" @done="doneTimer" />
            <div class="flex justify-between items-center">
                <n-skeleton v-if="!dashBoard.localization" class="inline-block" :width="215" :height="20" :sharp="false" />
                <div v-else class="text-sm text-gray-600 dark:text-white/75 font-semibold text-left">
                    {{ dashBoard.localization.dashboard_account_info_title }}
                </div>
                <div class="flex justify-between items-center">
                    <div v-if="!dashBoard.localization" class="flex">
                        <n-skeleton class="inline-block" :width="14" :height="14" :sharp="false" />
                        <n-skeleton class="inline-block ml-2" :width="120" :height="14" :sharp="false" />
                    </div>
                    <n-checkbox
                        v-else
                        size="small"
                        :color="gl.mainColor"
                        :checked="autoUpdate"
                        :disabled="loading"
                        :label="dashBoard.localization.dashboard_account_info_auto_refresh_checkbox"
                        @update:checked="setAutoUpdate"/>

                    <n-skeleton v-if="!dashBoard.localization" class="inline-block ml-2" :width="66" :height="22" :sharp="false" />
                    <n-button
                        v-else
                        strong
                        size="tiny"
                        class="ml-2 rounded-md"
                        type="tertiary"
                        @click="update"
                        :disabled="loading || updateLoading"
                        :loading="updateLoading">
                        {{ dashBoard.localization.dashboard_account_info_auto_refresh_button }}
                    </n-button>
                </div>
            </div>
        </template>

        <template #default>
            <section class="flex">
                <div class="text-left">
                    <n-skeleton v-if="!dashBoard.localization" class="inline-block" :width="50" :height="14" :sharp="false" />
                    <div v-else class="text-lightMain">{{ dashBoard.localization?.dashboard_account_info_status }}</div>

                    <n-skeleton v-if="loading" class="inline-block mt-1" :width="90" :height="24" :sharp="false" />
                    <div v-else class="text-gray-600 dark:text-white/75 text-base font-semibold mt-1">{{ refs.status }}</div>
                </div>
                <div class="text-left ml-6">
                    <n-skeleton v-if="!dashBoard.localization" class="inline-block" :width="30" :height="14" :sharp="false" />
                    <div v-else class="text-lightMain">{{ dashBoard.localization?.account_info_id }}</div>

                    <n-skeleton v-if="loading" class="inline-block mt-1" :width="20" :height="24" :sharp="false" />
                    <div v-else class="text-gray-600 dark:text-white/75 text-base font-semibold mt-1">{{ env.ID }}</div>
                </div>
                <div class="text-left ml-6">
                    <n-skeleton v-if="!dashBoard.localization" class="inline-block" :width="120" :height="14" :sharp="false" />
                    <div v-else class="text-lightMain">{{ dashBoard.localization?.dashboard_account_info_commission_balance }}</div>

                    <n-skeleton v-if="loading" class="inline-block mt-1" :width="130" :height="24" :sharp="false" />
                    <div v-else class="flex items-center text-base font-semibold" :class="gl.balance?.balance > 0 ? 'text-green-600' : 'text-red-500'">
                    {{ gl.balance?.balance }}
                    <rb-coin class="w-5 ml-2" :coin="currency" />
                    </div>
                </div>
                <div class="flex-grow flex items-center justify-end">
                    <n-skeleton v-if="!dashBoard.localization" class="inline-block mr-2" :width="160" :height="34" :sharp="false" />
                    <n-button
                        v-else
                        strong
                        size="medium"
                        class="mr-2 rounded-md text-white/90"
                        :color="gl.mainColor"
                        :disabled="loading"
                        @click="showModal = true">
                        + {{ dashBoard.localization?.dashboard_account_info_topup_balance_button }}
                    </n-button>
                </div>
            </section>
            <section class="flex justify-start mt-2">
                <n-tag v-if="gl.timeToShutdown?.timeToShutdown || gl.timeToShutdown?.timeToStop" type="error" size="small" round>
                    <span class="mr-1">{{ gl.timeToShutdown.status === 2 ? refs.localization.header.stop : refs.localization.header.suspend }}</span>
                    <strong @click="showModal = true" class="underline cursor-pointer hover:no-underline">
                        {{ gl.timeToShutdown.status === 2 ? gl.timeToShutdown?.timeToStop : gl.timeToShutdown?.timeToShutdown }}
                    </strong>
                </n-tag>
            </section>
        </template>
        <template #footer>
            <section class="w-full flex justify-between">
                <section class="flex">
                    <n-skeleton v-if="!refs.localization" class="inline-block mr-2" :width="160" :height="34" :sharp="false" />
                    <n-button
                        v-else
                        secondary
                        size="tiny"
                        type="default"
                        class="mr-2 rounded-md"
                        @click="window.open('https://desk.revenuebot.io/en/%D0%B1%D0%B0%D0%B7%D0%B0-%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B9', '_blank')">
                        {{ refs.localization.header.knowledge_base }}
                    </n-button>

                    <n-skeleton v-if="!refs.localization" class="inline-block mr-2" :width="160" :height="34" :sharp="false" />
                    <n-button
                        v-else
                        secondary
                        size="tiny"
                        type="default"
                        class="mr-2 rounded-md"
                        @click="window.open('https://appt.link/consultation_from_support/revenuebot', '_blank')">
                        {{ refs.localization.header.free_consultations }}
                    </n-button>

                    <n-skeleton v-if="!refs.localization" class="inline-block mr-2" :width="160" :height="34" :sharp="false" />
                    <n-button
                        v-else
                        secondary
                        size="tiny"
                        type="default"
                        class="mr-2 rounded-md"
                        @click="window.open('https://www.youtube.com/channel/UCrAyFAxvDzPIwYKgdSDwCFg', '_blank')">
                        {{ refs.localization.header.educational_videos }}
                    </n-button>
                </section>
                <n-skeleton v-if="!refs.localization" class="inline-block mr-2" :width="160" :height="34" :sharp="false" />
                <n-button
                    v-else
                    secondary
                    size="tiny"
                    type="default"
                    class="rounded-md"
                    @click="window.open(refs.lang === 'ru'
                            ? 'https://t.me/revenuebot_user'
                            : 'https://t.me/revenuebot_users_en', '_blank')">
                    <template #icon>
                        <telegram-icon class="fill-[#1A6DE0]" />
                    </template>
                    UsersChat
                </n-button>
            </section>
        </template>
    </n-card>
    <topup-balance
        v-model:show="showModal" />
</section>
</template>

<script>
// general
import general from './general';

// vue
import { ref, computed, h } from 'vue';

// naive-ui
import {
    NTag,
    NCard,
    NIcon,
    NAlert,
    NButton,
    NSelect,
    NAvatar,
    NCheckbox,
    NSkeleton,
    useMessage } from 'naive-ui';

// i18n
import { useI18n } from 'vue-i18n';

// icons
import {
    Star12Filled,
    Copy16Regular,
    Dismiss16Filled } from '@vicons/fluent';

// services
import Api from '@services/api';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useUser } from '@store/user';
import { useDashBoard } from '@store/ts/dashBoard';

// components
import RbCoin from '@components/rb-coin';
import TopupBalance from '@components/topup-balance';
import Progress from '@components/base/progress.vue';
import TelegramIcon from '@components/icons/telegram.vue';

export default {
    name: 'AccountInfo',
    components: {
        NTag,
        NCard,
        NIcon,
        NAlert,
        RbCoin,
        NSelect,
        NButton,
        Progress,
        NCheckbox,
        NSkeleton,
        TopupBalance,
        Star12Filled,
        TelegramIcon,
        Copy16Regular,
        Dismiss16Filled,
    },
    setup() {
        // store
        const gl = useGl();
        const env = useEnv();
        const refs = useRefs();
        const dashBoard = useDashBoard();
        

        // 18n
        const { t } = useI18n();

        // vars
        const percentage = ref(0);
        const progress = ref();
        const selectionCurrency = ref();
        const showModal = ref(false);
        const autoUpdate = ref(false);
        const updateLoading = ref(false);
        const loading = computed(() => gl.loading || dashBoard.loading);

        const currency = computed(() => refs.currencies.find(el => el.id === env.profitSymbol));

        const setAutoUpdate = (e) => {
            autoUpdate.value = e;
        };

        const doneTimer = async () => {
            updateLoading.value = true;
            await forceUpdate();
            updateLoading.value = false;

            progress.value.start();
        };

        const update = async () => {
            updateLoading.value = true;
            if (autoUpdate.value) progress.value.stop();

            await forceUpdate();

            if (autoUpdate.value) progress.value.start();
            updateLoading.value = false;
        };

        const forceUpdate = async () => {
            const result = await Promise.allSettled([
                Api.dashBoardRefs(),
                Api.getEnv(),
                Api.dashBoard({
                    dashboard_block: 'bot_stats',
                }),
            ]);

            dashBoard.refs = result[0].value.data;
            dashBoard.botsStatsInfo = result[2].value.data.bot_stats;
        };

        const topupSelectMethods = computed(() => {
            if (!Array.isArray(env.topupMethods)) return [];

            return env.topupMethods.map(cur => {
                const curEntry = refs.currencies.find(({ id }) => id === cur.currency);
                if (curEntry) {
                    cur.label = cur.title;
                    cur.value = cur.id;

                    return {...curEntry, ...cur};
                }
                return null;
            }).filter(cur => cur);
        });

        const renderSingleSelectTag = ({ option }) => {
            return h(
                'div',
                {
                    style: {
                        display: 'flex',
                        alignItems: 'center',
                    },
                },
                [
                    h(NAvatar, {
                        src: 'https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg',
                        round: true,
                        size: 24,
                        style: {
                            marginRight: '12px',
                        },
                    }),
                    option.label,
                ],
            );
        };

        const renderLabel = (option) => {
            return h(
                'div',
                {
                    style: {
                        display: 'flex',
                        alignItems: 'center',
                    },
                },
                [
                    h(RbCoin, {
                        class: 'ml-2 my-2',
                        coin: option,
                    }),
                    h(
                        'div',
                        {
                            style: {
                                marginLeft: '12px',
                                padding: '4px 0',
                            },
                        },
                        [
                            h('div', null, [option.label]),
                        ],
                    ),
                ],
            );
        };

        const copy = (s) => {
            navigator.clipboard.writeText(s);
            gl.showNotification({
                type: 'success',
                msg: t('copied'),
            });
        };

        const setSelectionCurrency = (e) => {
            selectionCurrency.value = topupSelectMethods.value.find(({ id }) => id === e);
            console.log(selectionCurrency.value);
        };

        return {
            t,
            gl,
            env,
            refs,
            window,
            loading,
            currency,
            progress,
            showModal,
            dashBoard,
            percentage,
            autoUpdate,
            updateLoading,
            selectionCurrency,
            topupSelectMethods,
            renderSingleSelectTag,
            copy,
            update,
            doneTimer,
            renderLabel,
            setAutoUpdate,
            setSelectionCurrency,
        };
    // 
    },
};
</script>

<style lang="scss" scoped>
:deep(a) {
  color: #4949D9;

  &:hover {
    text-decoration: underline;
  }
}
</style>