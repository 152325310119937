<template>
<n-card
    size="small"
    :bordered="false"
    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
    :segmented="{
      content: true,
      footer: 'soft'
    }">
    <template #default>
        <div class="border border-dashed border-gray-600 rounded-md flex flex-col items-center py-6">
            <WalletsIcon class="w-24 fill-current" />
            <n-button
                strong
                class="mt-4 text-white/90 rounded-md"
                :color="gl.mainColor"
                @click="show = true">
                + {{ wallets.localization.wallets_create_title_button }}
            </n-button>
        </div>
    </template>
</n-card>

<base-modal-desktop
    width="700px"
    :label="mainButtonLabel"
    v-model:show="show">
    <template #default>
        <div class="relative">
            <section class="flex">
                <rb-input
                    id="name"
                    class="w-6/12 pr-2"
                    :label="dataForm.name.title"
                    :status="dataForm.name.status === 'error' ? 'error' : undefined"
                    :msg="dataForm.name.msg"
                    :placeholder="dataForm.name.placeholder"
                    v-model:value="dataForm.name.value"
                    @update:value="dataForm.name.status = null, dataForm.name.msg = null" />
                
                <rb-select
                    v-if="mode === 'create'"
                    class="w-6/12 pl-2"
                    :label="dataForm.exchange.title"
                    :options="exchangeOptions"
                    :status="dataForm.exchange.status === 'error' ? 'error' : undefined"
                    :msg="dataForm.exchange.msg"
                    v-model:value="dataForm.exchange.value"
                    @update:value="dataForm.exchange.status = null, dataForm.exchange.msg = null" />
                <div v-else class="text-left w-4/12 px-2">
                    <div class="text-md">{{ dataForm.exchange.title }}</div>
                    <div
                        class="rb-td-stock-icon m-0 h-12"
                        :class="`rb-td-stock-icon-${exchange(dataForm.exchange.value)?.code_name}`">
                    </div>
                </div>
            </section>

            <template v-if="dataForm.currency.show">
                <n-divider />
                <div class="flex items-start">
                    <rb-currencies
                        v-if="mode === 'create'"
                        class="pr-2 w-6/12"
                        :label="dataForm.currency.title"
                        :options="currencyOptions"
                        :status="dataForm.currency.status === 'error' ? 'error' : undefined"
                        :msg="dataForm.currency.msg"
                        v-model:value="dataForm.currency.value"
                        @update:value="dataForm.currency.status = null, dataForm.currency.msg = null" />
                    <div v-else class="text-left w-4/12 pl-2">
                        <div class="text-me">{{ dataForm.currency.title }}</div>
                        <div class="flex items-center mt-3">
                            <rb-coin class="h-6" :coin="currency(dataForm.currency.value)" />
                            <div class="text-center font-semibold ml-2">{{ currency(dataForm.currency.value)?.title }}</div>
                        </div>
                    </div>
                    <div class="w-6/12 pl-2">
                        <rb-input
                            id="amount"
                            type="percent"
                            :helpWarning="wallets.localization.wallets_minimum_depo_info"
                            :label="dataForm.amount.title"
                            :status="dataForm.amount.status === 'error' ? 'error' : undefined"
                            :msg="dataForm.amount.msg"
                            :placeholder="dataForm.amount.placeholder"
                            v-model:value="dataForm.amount.value"
                            @update:value="dataForm.amount.status = null, dataForm.amount.msg = null">
                            <template #beforeInput>
                                <n-radio-group
                                    class="mr-4"
                                    v-model:value="dataForm.type">
                                    <n-radio-button
                                        v-for="option in walletTypesOptions"
                                        :key="option.value"
                                        :value="option.value"
                                        :label="option.label" />
                                </n-radio-group>
                            </template>
                        </rb-input>
                    </div>
                </div>

                <n-divider />
                <section>
                    <div class="flex items-end">
                        <rb-select
                            class="flex-grow pr-2"
                            :label="dataForm.api_key.title"
                            :options="apiKeysOptions"
                            :status="dataForm.api_key.status === 'error' ? 'error' : undefined"
                            :msg="dataForm.api_key.msg"
                            v-model:value="dataForm.api_key.value"
                            @update:value="dataForm.api_key.status = null, dataForm.api_key.msg = null" />
                        <div class="pl-2">
                            <div class="flex">
                                <n-button
                                    class="text-white/90 rounded-md"
                                    :color="gl.mainColor"
                                    :disabled="!isExchangeSelected"
                                    @click="walletApiKeyDialog = true">
                                    + {{ wallets.localization.wallets_api_createnew }}
                                </n-button>
                                <n-button
                                    secondary
                                    class="ml-1 rounded-md"
                                    :disabled="!canCheckBalance"
                                    @click="onCheckBalance">
                                    {{ wallets.localization.wallets_check_balance_button }}
                                </n-button>
                            </div>
                        </div>
                    </div>

                    <rb-checkbox
                        class="mt-4"
                        :disabled="!canCheckBalance"
                        :label="wallets.localization.wallets_show_all_non_zero"
                        v-model:checked="showAllNonZeroBalances" />

                    <n-card
                        v-if="symbolBalance || symbolTotalBalance"
                        size="small"
                        :bordered="false"
                        class="overflow-hidden shadow rounded-md dark:bg-darkbg bg-white w-full mt-6"
                        :segmented="{
                            content: true,
                            footer: 'soft'
                        }">
                        <div class="w-full flex justify-between">
                            <n-statistic
                                v-if="symbolBalance"
                                tabular-nums
                                class="text-left"
                                :label="wallets.localization['wallets_symbol_balance__available_amount']">
                                <n-number-animation
                                    :from="0"
                                    :to="+symbolBalance"
                                    :precision="8"
                                />
                            </n-statistic>
                            <n-statistic
                                v-if="symbolTotalBalance"
                                tabular-nums
                                class="text-left"
                                :label="wallets.localization['wallets_symbol_balance__balance_with_orders']">
                                <n-number-animation
                                    :from="0"
                                    :to="+symbolTotalBalance"
                                    :precision="8"
                                />
                            </n-statistic>
                        </div>
                    </n-card>

                    <div
                        v-if="showAllNonZeroBalances && allSymbolsBalances"
                        class="flex flex-wrap items-stretch mt-6 w-full">
                        <div
                            v-for="(item, i) in allSymbolsBalances"
                            :key="i"
                            class="mb-4 w-6/12 even:pl-2 odd:pr-2">
                            <n-card
                                size="small"
                                :bordered="false"
                                class="overflow-hidden shadow rounded-md dark:bg-darkbg bg-white h-full"
                                :segmented="{
                                    content: true,
                                    footer: 'soft'
                                }">
                                <div class="flex text-gray-600 dark:text-white/75">
                                    <div class="w-3/12 flex flex-col items-center">
                                        <rb-coin class="h-6" :coin="currency(item.symbol)" />
                                        <div class="font-semibold mt-2 text-[10px] text-center">{{ currency(item.symbol)?.title }}</div>
                                    </div>

                                    <div class="w-9/12 text-xs">
                                        <div class="text-right">
                                            {{ wallets.localization.wallets_check_balance_available }}
                                            <span class="font-bold text-main">{{ item.available }}</span>
                                        </div>
                                        <div class="text-right">
                                            {{ wallets.localization.wallets_extra_balance__with_orders }}
                                            <span class="text-main">{{ item.total }}</span>
                                        </div>
                                    </div>
                                </div>
                            </n-card>
                        </div>
                    </div>
                </section>
            </template>
            
            <section>
                <n-divider />
                <rb-input
                    id="comment"
                    class="w-full"
                    type="textarea"
                    :label="dataForm.comment.title"
                    :status="dataForm.comment.status === 'error' ? 'error' : undefined"
                    :msg="dataForm.comment.msg"
                    :placeholder="dataForm.comment.placeholder"
                    v-model:value="dataForm.comment.value"
                    @update:value="dataForm.comment.status = null, dataForm.comment.msg = null" />
            </section>
        </div>
    </template>
    <template #footer>
        <div class="flex justify-end">
            <!-- :disabled="!canCreateWallet" -->
            <n-button
                secondary
                type="default"
                class="rounded-md text-gray-600 dark:text-white/75"
                @click="hideModal">
                {{ refs.localization.dialogs.cancel }}
            </n-button>
            <n-button
                class="ml-2 text-white/90 rounded-md"
                @click="onActionWallet"
                :loading="localLoading"
                :disabled="localLoading"
                :color="gl.mainColor" >
                {{ applyLabel }}
            </n-button>
        </div>
    </template>
</base-modal-desktop>

<base-modal-desktop
    v-model:show="walletApiKeyDialog">
    <template #headerContent>
        <span :class="`h-8 inline-block rb-td-stock-icon rb-td-stock-icon-${exchange(exchangeInfo.id).code_name}`"></span>
    </template>
    <template #default>
        <rb-input
            :label="apiKeyDataForm.name.title"
            :placeholder="apiKeyDataForm.name.placeholder"
            :status="apiKeyDataForm.name.status === 'error' ? 'error' : undefined"
            :msg="apiKeyDataForm.name.msg"
            v-model:value="apiKeyDataForm.name.value"
            @update:value="apiKeyDataForm.name.status = null, apiKeyDataForm.name.msg = null" />
        <n-divider />

        <rb-input
            :label="apiKeyDataForm.key.title"
            :placeholder="apiKeyDataForm.key.placeholder"
            :status="apiKeyDataForm.key.status === 'error' ? 'error' : undefined"
            :msg="apiKeyDataForm.key.msg"
            v-model:value="apiKeyDataForm.key.value"
            @update:value="apiKeyDataForm.key.status = null, apiKeyDataForm.key.msg = null" />
        <n-alert v-if="exchangeMetaInfo" class="rounded-md" type="warning" size="small">
            <div class="notes text-xs" v-html="exchangeMetaInfo"></div>
        </n-alert>
        <n-alert v-if="exchangeMeta2Info" class="rounded-md mt-4" type="warning" size="small">
            <div class="notes text-xs" v-html="exchangeMeta2Info"></div>
        </n-alert>
        <n-divider />

        <rb-input
            :label="apiKeyDataForm.sign.title"
            :placeholder="apiKeyDataForm.sign.placeholder"
            :status="apiKeyDataForm.sign.status === 'error' ? 'error' : undefined"
            :msg="apiKeyDataForm.sign.msg"
            v-model:value="apiKeyDataForm.sign.value"
            @update:value="apiKeyDataForm.sign.status = null, apiKeyDataForm.sign.msg = null" />
    </template>
    <template #footer>
        <div class="flex justify-end">
            <n-button
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                @click="onCreateApiKey">
                {{ wallets.localization.wallets_api_createnew_modal_add_new_button }}
            </n-button>
        </div>
    </template>
</base-modal-desktop>
</template>

<script>
// general
import general from './general';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbCheckbox from '@components/rb-checkbox';
import BaseModal from '@components/base/base-modal';
import RbCurrencies from '@components/rb-currencies';
import WalletsIcon from '@components/icons/wallets.vue';

// icons
import { Warning24Filled } from '@vicons/fluent';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NModal,
    NSpin,
    NButton,
    NDivider,
    NCollapse,
    NStatistic,
    NRadioGroup,
    NRadioButton,
    NCollapseItem,
    NNumberAnimation } from 'naive-ui';

export default {
    name: 'walletCreate',
    props: {
        data: Object,
    },
    components: {
        NIcon,
        NCard,
        NModal,
        NSpin,
        NAlert,
        RbCoin,
        RbInput,
        NButton,
        RbSelect,
        NDivider,
        NCollapse,
        BaseModal,
        NStatistic,
        RbCheckbox,
        WalletsIcon,
        NRadioGroup,
        NRadioButton,
        RbCurrencies,
        NCollapseItem,
        Warning24Filled,
        NNumberAnimation,
    },
    setup(props, context) {
       
        return {
            ...general(...arguments),
        };
    },
};
</script>