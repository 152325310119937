<template>
<base-drawer-mobile
    :label="label"
    :show="show"
    @update:show="!$event ? $emit('update:show', $event) : null">
    <template #default>
        <div v-if="!init" class="flex h-full justify-center">
            <n-spin size="small" />
        </div>
        <template v-else>
            <div class="flex items-start">
                <rb-input
                    id="name"
                    class="w-6/12 pr-2"
                    :label="dataForm.name.title"
                    :status="dataForm.name.status === 'error' ? 'error' : undefined"
                    :msg="dataForm.name.msg"
                    :placeholder="dataForm.name.placeholder"
                    v-model:value="dataForm.name.value"
                    @update:value="dataForm.name.status = null, dataForm.name.msg = null" />
                    
                <div class="w-6/12 pl-2 flex items-end">
                    <rb-select
                        :label="dataForm.exchange.title"
                        :options="exchangeOptions"
                        :disabled="isUpdate || exchangeId"
                        :status="dataForm.exchange.status === 'error' ? 'error' : undefined"
                        :msg="dataForm.exchange.msg"
                        class="flex-grow"
                        v-model:value="dataForm.exchange.value"
                        @update:value="dataForm.exchange.status = null, dataForm.exchange.msg = null" />
                    <div
                        v-if="isExchangeSelected"
                        class="rb-td-stock-icon ml-2 mb-[10px] h-5"
                        :class="`rb-td-stock-icon-${exchange(dataForm.exchange.value)?.code_name}`">
                    </div>
                </div>
            </div>
            <n-divider />

            <rb-input
                :label="dataForm.key.title"
                :placeholder="dataForm.key.placeholder"
                :status="dataForm.key.status === 'error' ? 'error' : undefined"
                :msg="dataForm.key.msg"
                v-model:value="dataForm.key.value"
                @update:value="dataForm.key.status = null, dataForm.key.msg = null" />
            <n-alert v-if="exchangeMetaInfo" class="rounded-md" type="warning" size="small">
                <div class="notes text-xs" v-html="exchangeMetaInfo"></div>
            </n-alert>
            <n-alert v-if="exchangeMeta2Info" class="rounded-md mt-4" type="warning" size="small">
                <div class="notes text-xs" v-html="exchangeMeta2Info"></div>
            </n-alert>
            <n-divider />

            <rb-input
                :label="dataForm.sign.title"
                :placeholder="dataForm.sign.placeholder"
                :status="dataForm.sign.status === 'error' ? 'error' : undefined"
                :msg="dataForm.sign.msg"
                v-model:value="dataForm.sign.value"
                @update:value="dataForm.sign.status = null, dataForm.sign.msg = null" />
        </template>
    </template>
    <template #footer v-if="init">
        <div class="flex justify-end items-center">
            <rb-checkbox
                class="mr-4"
                :label="dataForm.show_dashboard.title"
                v-model:checked="dataForm.show_dashboard.value" />
            <n-button
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                :disabled="!canDoingSomethingWidthApiKey || localLoading"
                :loading="localLoading"
                @click="onDoingSomethingWithApiKey">
                {{ label }}
            </n-button>
        </div>
    </template>
</base-drawer-mobile>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NSpin,
    NAlert,
    NDivider,
    NButton } from 'naive-ui';

export default {
    name: 'rb-api-key-cr-desktop',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        exchangeId: {
            type: [String, Number],
            default: null,
        },
        apiKeyId: {
            type: [String, Number],
            default: null,
        },
    },
    components: {
        NSpin,
        NAlert,
        NButton,
        NDivider,
    },
    setup(props, context) {
        return {
            ...general(...arguments),
        };
    },
};
</script>