// vue
import { computed } from 'vue';

export default function (props, context) {
    const localOptions = computed(() => props.options.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    return {
        localOptions,
    };
}