<template>
<h4 class="text-left px-3 my-2 text-base text-gray-600 dark:text-white/75 font-semibold">
    {{ refs.localization.sidebar.quickstart }}
</h4>
<section class="min-h-screen px-3">
    <template v-if="init">
        <n-alert v-if="!botId && isExchangeFutures" class="my-4 rounded-md" type="warning" size="small">
            <div class="notes text-[10px]" v-html="quickStart.localization['quick_start/simulate_mode/not_available']"></div>
        </n-alert>

        <template v-if="!botId">
            <!-- bot simulate -->
            <rb-checkbox
                v-if="!isExchangeFutures"
                popoverType="warning"
                class="mb-4"
                :help="dataForm.simulate.value ? quickStart.localization['quick_start/simulate_mode/notes'] : null"
                :label="'- ' + quickStart.localization['quick_start/simulate_mode/label']"
                v-model:checked="dataForm.simulate.value" />

            <!-- bot name -->
            <rb-input
                class="mb-4"
                :status="dataForm.name.status === 'error' ? 'error' : undefined"
                :msg="dataForm.name.msg"
                :label="quickStart.localization['quick_start/bot_name/label']"
                :placeholder="quickStart.localization['quick_start/bot_name/placeholder']"
                v-model:value="dataForm.name.value"
                @update:value="dataForm.name.status = null, dataForm.name.msg = null" />

            <!-- exchange -->
            <template v-if="isBotNameValid">
                <n-divider />
                <div class="mb-4 flex justify-between items-end">
                    <rb-select
                        filterable
                        popoverType="warning"
                        class="flex-grow"
                        :help="exchangeMeta3Info ? exchangeMeta3Info : null"
                        :label="quickStart.localization['quick_start/exchange/label']"
                        :options="exchangesSorted"
                        :status="dataForm.exchange.status === 'error' ? 'error' : undefined"
                        :msg="dataForm.exchange.msg"
                        v-model:value="dataForm.exchange.value"
                        @update:value="dataForm.exchange.status = null, dataForm.exchange.msg = null" />
                    <span
                        v-if="dataForm.exchange.value !== -1"
                        class="inline-block rb-td-stock-icon ml-4 w-16 h-8"
                        :class="`rb-td-stock-icon-${exchange(dataForm.exchange.value)?.code_name}`">
                    </span>
                </div>
            </template>

            <!-- api key -->
            <template v-if="isExchangeSelected">
                <n-divider />
                <api-keys
                    class="mt-6"
                    :apiKeysRefs="apiKeysRefs"
                    :selectTitle="quickStart.localization['quick_start/api_key/label']"
                    :emptyText="quickStart.localization['quick_start/api_key/no_keys_for_exchange/label']"
                    :mainTitle="quickStart.localization['quick_start/api_key/title']"
                    :loading="dataForm.exchange.loading"
                    :name="dataForm.api_key.name"
                    :exchange="dataForm.exchange"
                    v-model="dataForm.api_key.value"
                    @setApiKey="setApiKey" />
            </template>

            <!-- pair -->
            <template v-if="isExchangeSelected && !dataForm.simulate.value">
                <n-divider />
                <rb-pairs
                    :pager="pager"
                    :pairsRefs="pairsRefs"
                    :loading="dataForm.pair.loading"
                    :recommendedPairsRef="recommendedPairsRef"
                    :tickerMessage="tickerMessage"
                    :msg="dataForm.pair.msg"
                    :status="dataForm.pair.status === 'error' ? 'error' : undefined"
                    :label="quickStart.localization['quick_start/pair/label']"
                    :title="quickStart.localization['quick_start/recommended_pairs/title']"
                    :howToChooseInfo="quickStart.localization['quick_start/pair/how_to_choose']"
                    :gridNavLabel="quickStart.localization['quick_start/recommended_pairs/show_per_page']"
                    :recommendedPairsInfo="quickStart.localization['quick_start/quick_start/recommended_pairs/info']"
                    v-model="dataForm.pair.value">
                </rb-pairs>
            </template>

            <!-- algo -->
            <template v-if="isExchangeSelected">
                <n-divider />
                <rb-select
                    popoverType="warning"
                    :help="quickStart.localization['quick_start/algo/algorithms_info']"
                    :label="quickStart.localization['quick_start/algo/label']"
                    :options="algosOptions"
                    :status="dataForm.algo.status === 'error' ? 'error' : undefined"
                    :msg="dataForm.algo.msg"
                    v-model:value="dataForm.algo.value"
                    @update:value="dataForm.algo.status = null, dataForm.algo.msg = null" />

                <!-- wallet -->
                <template v-if="isAlgoSelected">
                    <n-divider />
                    <rb-wallet-select-v2
                        v-model="dataForm.wallet.value"
                        :mainData="dataForm"
                        :walletModelInfo="walletModelInfo"
                        :selectOptionsRef="pairWalletsRefs"
                        :depoInfo="quickStart.localization['quick_start/wallet/depo_info']"
                        :coinSymbolForWallet="coinSymbolForWallet"
                        :buttonCreateLabel="quickStart.localization['quick_create/wallet/create_button']"
                        :tabOptionsRef="quickStart.assignmentTypes"
                        :selectLabel="quickStart.localization['quick_start/wallet/label']"
                        :mainLabel="quickStart.localization['quick_start/wallet/title']"
                        :buttonCheckLabel="quickStart.localization['quick_create/wallet/check_balance']"
                        :symbolBalanceLabel="quickStart.localization['quick_create/wallet/balance__available']"
                        :symbolTotalBalanceLabel="quickStart.localization['quick_create/wallet/balance__with_orders']"
                        @setWallet="setWallet" >
                        <template #messages>
                            <div v-if="dataForm.wallet.status === 'error'" class="w-full mt-2 text-red-400 text-right">{{ dataForm.wallet.msg }}</div>
                        </template>
                    </rb-wallet-select-v2>
                </template>

                <!-- leverage -->
                <template v-if="isExchangeFutures && isAlgoSelected">
                    <n-divider />
                    <rb-shoulder
                        :pair="leverageMinMax"
                        :help="quickStart.localization['quick_start/leverage/info']"
                        :disabled="!isWalletSelected"
                        :label="quickStart.localization['quick_start/leverage/label']"
                        v-model="dataForm.leverage.value">
                        <template #messages>
                            <div v-if="dataForm.leverage.status === 'error'" class="w-full mt-2 text-red-400 text-right">{{ dataForm.leverage.msg }}</div>
                        </template>
                    </rb-shoulder>
                </template>
            </template>
            
            <template v-if="isAlgoSelected">
                <n-divider />
                <rb-select
                    popoverType="warning"
                    :help="quickStart.localization['quick_start/order_matrix/info']"
                    :label="quickStart.localization['quick_start/order_matrix/label']"
                    :options="ordersMatrixOrdered"
                    :status="dataForm.order_matrix.status === 'error' ? 'error' : undefined"
                    :msg="dataForm.order_matrix.msg"
                    v-model:value="dataForm.order_matrix.value"
                    @update:value="dataForm.order_matrix.status = null, dataForm.order_matrix.msg = null" />

                <template v-if="dataForm.order_matrix.value != -1 && compMatrixMaxCountAllowed">
                    <n-divider />
                    <rb-part-orders
                        v-model:checked="dataForm.part_orders_enabled.value"
                        v-model:partOrdersUnstopable="dataForm.part_orders_unstopable.value"
                        v-model="dataForm.part_orders_value.value"
                        :pair="{ min: 1, max: compMatrixMaxCount }"
                        :isExchangeFutures="isExchangeFutures"
                        :help="quickStart.localization['quick_start/matrix/part_orders_unstopable/info']"
                        :partOrdersUnstopableTitle="quickStart.localization['quick_start/matrix/part_orders_unstopable/label']"
                        :checkBoxTitle="quickStart.localization['quick_start/matrix/part_orders_enabled']" >
                    </rb-part-orders>
                    <div v-if="dataForm.part_orders_value.status === 'error'" class="w-full mt-2 text-red-400 text-right">{{ dataForm.part_orders_value.msg }}</div>
                </template>

                <template v-if="isWalletSelected && isOrdersMatrixSelected && !!exchangeTradeModes">
                    <n-divider />
                    <rb-select
                        popoverType="warning"
                        :help="quickStart.localization['quick_start/trade_mode/info']"
                        :label="quickStart.localization['quick_start/trade_mode/label']"
                        :options="exchangeTradeModes"
                        :status="dataForm.trade_mode.status === 'error' ? 'error' : undefined"
                        :msg="dataForm.trade_mode.msg"
                        v-model:value="dataForm.trade_mode.value"
                        @update:value="dataForm.trade_mode.status = null, dataForm.trade_mode.msg = null" />
                </template>

                <rb-checkbox
                    v-if="isTradeModeSelected"
                    popoverType="warning"
                    class="mt-4"
                    :help="quickStart.localization['quick_start/enable_filters/info']"
                    :label="quickStart.localization['quick_start/enable_filters/label']"
                    v-model:checked="dataForm.enable_filters.value" />
            </template>
        </template>

        <n-card
            v-else
            size="small"
            :bordered="false"
            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <template #default>
                <n-result
                    class="rounded-lg"
                    status="success"
                    :title="botMessage">
                    <template #default>
                        <ul class="text-xs">
                            <li class="mb-4">
                                <router-link :to="{name: 'bots.edit', params: {id: botId}}" class="text-main underline hover:no-underline">
                                    {{ quickStart.localization['quick_start/info/edit_bot'] }}
                                </router-link> — <span v-html="quickStart.localization['quick_start/info/edit_bot_descr']"></span>
                            </li>

                            <li class="mb-4">
                                <router-link :to="{name: 'bots.review'}" class="text-main underline hover:no-underline">
                                    {{ quickStart.localization['quick_start/info/bots_list'] }}
                                </router-link> — <span v-html="quickStart.localization['quick_start/info/bots_list_descr']"></span>
                            </li>
                            
                            <router-link :to="{name: 'trade-statistic'}" v-slot="{ href, navigate }">
                                <li>
                                    <a :href="href" v-html="quickStart.localization['quick_start/info/bots_stats']" @click="navigate"></a> 
                                    — <span v-html="quickStart.localization['quick_start/info/bots_stats_list']"></span>
                                </li>
                            </router-link>
                            
                            <li class="mb-4" v-html="quickStart.localization['quick_start/info/how_to_control']"></li>
                            <li v-html="quickStart.localization['quick_start/info/how_to_stats']"></li>
                        </ul>
                    </template>
                    <template #footer>
                        <n-button
                            strong
                            class="rounded-md text-white/90"
                            :color="gl.mainColor"
                            @click.prevent="resetAndCreateNew" >
                            {{ quickStart.localization['quick_start/create_new_bot_btn'] }}
                        </n-button>
                    </template>
                </n-result>
            </template>
        </n-card>

        <section class="flex sticky bottom-[56px] overflow-x-auto v-x-scroll mt-6 z-50" v-if="!botId">
            <n-button
                strong
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                :loading="createLoading"
                :disabled="createLoading || !canCreateBot"
                @click.prevent="onCreateBot(false)" >
                {{ quickStart.localization['quick_start/create_bot_btn'] }}
            </n-button>

            <n-button
                strong
                class="mx-2 rounded-md text-white/90"
                :color="gl.mainColor"
                :loading="createLoading"
                :disabled="createLoading || !canCreateBot"
                @click.prevent="onCreateBot(false, true)" >
                {{ quickStart.localization['quick_start/create_bot_and_edit_btn'] }}
            </n-button>

            <n-button
                strong
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                :loading="createLoading"
                :disabled="createLoading || !canCreateBot"
                @click.prevent="onCreateBot(true)" >
                {{ quickStart.localization['quick_start/create_bot_and_start_btn'] }}
            </n-button>
        </section>
    </template>
</section>
</template>

<script>
// general
import general from './general';

// components
import RbInput from '@components/rb-input/mobile.vue';
import ApiKeys from '@components/api-keys/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';
import RbShoulder from '@components/rb-shoulder/mobile.vue';
import RbPairs from '@components/rb-pairs/mobile.vue';
import RbPartOrders from '@components/rb-part-orders/mobile.vue';
import RbWalletSelectV2 from '@components/rb-wallet-select-v2/mobile.vue';

// UI
import {
    NCard,
    NTabs,
    NSpin,
    NAlert,
    NInput,
    NButton,
    NResult,
    NDivider,
    NTabPane } from 'naive-ui';

export default {
    name: 'quick-start-mobile',
    components: {
        NCard,
        NTabs,
        NSpin,
        NAlert,
        NInput,
        NButton,
        RbPairs,
        ApiKeys,
        RbInput,
        NResult,
        RbSelect,
        NTabPane,
        NDivider,
        RbCheckbox,
        RbShoulder,
        RbPartOrders,
        RbWalletSelectV2,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>