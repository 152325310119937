// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';

// services
import ApiKeysService from'@services/apiKeysService';
import WalletsService from '@services/walletsService';
import ExchangesService from '@services/exchangesService';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useWallets } from '@store/wallets';

// shared
import { WALLET_DATA_FORM_FACTORY } from '@shared/factories';
import { formatUnexistedCurrsAsUnknown } from '@shared/util';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRoute, useRouter } from 'vue-router';

// naive-ui
import { useNotification } from 'naive-ui';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const wallets = useWallets();

    // ui
    const notification = useNotification();

    // router
    const route = useRoute();
    const router = useRouter();

    // i18n
    const { t } = useI18n();

    // vars
    const { _ } = window;
    const apiKey = ref();
    const show = ref(false);
    const mode = ref('create');
    const init = ref(false);
    const apiKeysRefs = ref();
    const exchangeInfo = ref();
    const collapseItem = ref();
    const localLoading = ref(false);
    const balancesRefs = ref({});
    const showAllNonZeroBalances = ref(true);
    const walletApiKeyDialog = ref(false);
    const dataForm = reactive({});

    watch(() => route.params.id, v => {
        if (v)
            show.value = true;
    });

    watch(show, v => {
        if (v) {
            initModule();
        } else {
            context.emit('clear');
            mode.value = 'create';
            router.push({ name: 'wallets' });
        }
    });

    watch(() => dataForm.exchange?.value, async v => {
        if (!init.value) return;

        localLoading.value = true;
      
        if (v != -1) {
            try {
                if (mode.value === 'create')
                    dataForm.currency.value = -1;

                await Promise.allSettled([
                    getFullInfo(v, [false, false]),
                    getApiKeysList(v, [false, false]),
                ]);
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
        }

        localLoading.value = false;
    });

    watch(() => dataForm.api_key?.value, () => {
        balancesRefs.value = {};
    });

    // computeds
    const isExchangeSelected = computed(() => dataForm.exchange.value !== -1 && exchangeInfo.value);
    const apiKeySelected = computed(() => dataForm.api_key.value !== -1);
    const currencySelected = computed(() => dataForm.currency.value !== -1);
    const canCheckBalance = computed(() => isExchangeSelected.value && apiKeySelected.value);
    const exchangeOptions = computed(() => refs.exchanges.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const currencyOptions = computed(() => Array.isArray(exchangeInfo.value?.currencies)
        ? formatUnexistedCurrsAsUnknown(refs, exchangeInfo.value.currencies).map(el => ({
            value: el.id,
            label: el.title,
            ...el,
        }))
        : []);

    const walletTypesOptions = computed(() => refs.walletTypes.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const apiKeysOptions = computed(() => Array.isArray(apiKeysRefs.value?.records)
        ? apiKeysRefs.value.records.map(el => ({
            value: el.id,
            label: el.name,
            ...el,
        })) : []);

    const allSymbolsBalances = computed(() => {
        const curSymbol = dataForm.currency.value;
        let balancesMap = [], balancesCheckMap = {};
      
        _.forOwn(balancesRefs.value, (value, key) => {
            const [symbol, hasTotal] = key.split('_');
            const symEntry = _.get(balancesCheckMap, symbol, {available: 0.00, total: 0.00});
          
            if (hasTotal) {
                symEntry.total = value;
            } else {
                symEntry.available = value;
            }
            balancesCheckMap[symbol] = symEntry;
        });
      
        if (curSymbol && curSymbol != -1) {
            let curSymbolInfo = _.get(balancesCheckMap, curSymbol, {available: 0.00, total: 0.00});
            delete balancesCheckMap[curSymbol];
            balancesMap.push({symbol: curSymbol, ...curSymbolInfo});
        }
      
        _.forEach(Object.keys(balancesCheckMap).sort(), (symbol) => {
            const {available, total} = balancesCheckMap[symbol];
          
            if (parseFloat(total, 10) === 0) return;
          
            balancesMap.push({symbol, available, total});
        });
      
        return Object.keys(balancesRefs.value).length > 0 ? balancesMap : null;
    });

    const exchangeMetaInfo = computed(() => refs.exchanges.find(el => el.id === dataForm.exchange.value)?.meta);
    const exchangeMeta2Info = computed(() => refs.exchanges.find(el => el.id === dataForm.exchange.value)?.meta2);
    const canCreateWallet = computed(() => isExchangeSelected.value && currencySelected.value);

    const symbolBalance = computed(() => balancesRefs.value?.[dataForm.currency.value] || '');
    const symbolTotalBalance = computed(() => balancesRefs.value?.[`${dataForm.currency.value}_total`] || '');
    const mainButtonLabel = computed(() => props.data?.id ? wallets.localization.wallets_update_title_button : wallets.localization.wallets_create_title_button);
    const applyLabel = computed(() => props.data?.id ? wallets.localization.wallets_update_title_button : wallets.localization.wallets_create_title_button);

    const apiKeyDataForm = reactive({
        name: {
            value: '',
            title: wallets.localization['wallets_api_createnew_modal_name_f'],
            placeholder: wallets.localization['wallets_api_createnew_modal_name_i'],
            status: undefined,
            msg: undefined,
        },
        key: {
            value: '',
            title: wallets.localization['wallets_api_f'],
            placeholder: wallets.localization['wallets_api_createnew_modal_apikey_i'],
            status: undefined,
            msg: undefined,
        },
        sign: {
            value: '',
            title: wallets.localization['wallets_api_createnew_modal_sign_f'],
            placeholder: wallets.localization['wallets_api_createnew_modal_sign_i'],
            status: undefined,
            msg: undefined,
        },
    });

    const getFullInfo = async (id) => {
        localLoading.value = true;

        try {
            exchangeInfo.value = ( await ExchangesService.getFullInfo(id) ).data;
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };

        localLoading.value = false;
    };

    const getApiKeysList = async (id = dataForm.exchange.value) => {
        localLoading.value = true;

        try {
            apiKeysRefs.value = ( await ApiKeysService.getApiKeysList({exchanges: [id]}) ).data;
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };
      
        localLoading.value = false;
    };

    const walletsRefs = async () => {
        if (wallets.refs) return;

        localLoading.value = true;

        try {
            wallets.refs = ( await WalletsService.getRefs() ).data;
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };
      
        localLoading.value = false;
    };

    const initModule = () => {
        const res = WALLET_DATA_FORM_FACTORY(props.data);
        mode.value = props.data ? 'edit' : 'create';

        for (const [key, value] of Object.entries(res)) {
            if (key === 'name') {
                dataForm[key] = {
                    value,
                    title: wallets.localization['wallets_name_f'],
                    placeholder: wallets.localization['wallets_name_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'comment') {
                dataForm[key] = {
                    value,
                    title: wallets.localization['wallets_comment_f'],
                    placeholder: wallets.localization['wallets_comment_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'exchange') {
                dataForm[key] = {
                    value,
                    title: wallets.localization['wallets_exchange_f'],
                    placeholder: wallets.localization['wallets_exchange_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: true,
                    customField: true,
                };
            } else if (key === 'currency') {
                dataForm[key] = {
                    value,
                    title: wallets.localization['wallets_currency_f'],
                    placeholder: wallets.localization['wallets_currency_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: computed(() => isExchangeSelected.value),
                    customField: true,
                };
            } else if (key === 'amount') {
                dataForm[key] = {
                    value,
                    title: wallets.localization['wallets_amount_f'],
                    placeholder: wallets.localization['wallets_amount_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: computed(() => isExchangeSelected.value),
                    customField: true,
                };
            } else if (key === 'api_key') {
                dataForm[key] = {
                    value,
                    title: wallets.localization['wallets_api_f'],
                    placeholder: wallets.localization['wallets_api_i'],
                    status: undefined,
                    msg: undefined,
                    loading: false,
                    show: computed(() => isExchangeSelected.value),
                    customField: true,
                };
            } else dataForm[key] = value;
        };
    };

    const onCreateApiKey = async () => {
        gl.showLoading = true;

        const records = {
            exchange: dataForm.exchange.value,
            name: apiKeyDataForm.name.value,
            key: apiKeyDataForm.key.value,
            sign: apiKeyDataForm.sign.value,
            _formPath: 'walletApiKeyDialog',
        };

        const result = await ApiKeysService.addApiKeyToExchange(records.exchange, records);

        if (!result.data.status) {
            if (result.data?.errors_form) {

                for (let key in apiKeyDataForm) {
                    const fields = result.data.errors_form['walletApiKeyDialog'].fields;
                    const el = Object.keys(fields).find(el => el === key);

                    if (el) {
                        apiKeyDataForm[key].status = 'error';
                        apiKeyDataForm[key].msg = fields[el].msg;
                    } else {
                        apiKeyDataForm[key].status = 'success';
                        apiKeyDataForm[key].msg = undefined;
                    }
                }
            }
        } else {
            // clean apiKeyModel
            for (let key in apiKeyDataForm)
                apiKeyDataForm[key].value = '';

            // show messages
            result.postMessages.forEach(el => {
                notification[el.success ? 'success' : 'error']({
                    content: el.msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            });

            walletApiKeyDialog.value = false;

            await getApiKeysList();

            // change api key
            dataForm.api_key.value = result.data.records[0].id;

            router.go(-1);
        }

        gl.showLoading = false;
    };

    const onActionWallet = async () => {
        gl.showLoading = true;

        const records = {
            name: dataForm.name.value,
            comment: dataForm.comment.value,
            currency: dataForm.currency.value,
            exchange: dataForm.exchange.value,
            type: dataForm.type,
            amount: dataForm.amount.value,
            api_key: dataForm.api_key.value,
            _formPath: 'wallets.addNew',
        };

        let prepare;

        try {
            prepare = mode.value === 'create'
                ? await WalletsService.addNew(records)
                : await WalletsService.udpate({
                    id: dataForm.id,
                    ...records,
                });
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        }
      
        if (!prepare.data.status) {
            if (prepare.data?.errors_form) {

                for (let key in dataForm) {
                    const fields = prepare.data.errors_form['wallets.addNew'].fields;
                    const el = Object.keys(fields).find(el => el === key);

                    if (el) {
                        dataForm[key].status = 'error';
                        dataForm[key].msg = fields[el].msg;
                    } else {
                        if (typeof dataForm[key] === 'object' && dataForm[key] !== null) {
                            dataForm[key].status = 'success';
                            dataForm[key].msg = undefined;
                        }
                    }
                }
            }
        } else {
            show.value = false;
            if (mode.value !== 'create') {
                context.emit('updateWallet', prepare.data.record);
            } else {
                context.emit('addNewWallet', prepare.data.records[0]);
            }
            // clean apiKeyModel
            for (let key in dataForm) {
                if (typeof dataForm[key] === 'object' && dataForm[key] !== null)
                    dataForm[key].value = '';
            };

            // show messages
            prepare.postMessages.forEach(el => {
                notification.success({
                    content: el.msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            });
        }

        gl.showLoading = false;
    };

    const onCheckBalance = async () => {
        localLoading.value = true;

        const prepare = await WalletsService.getBalances(dataForm.api_key.value);

        if (!prepare.data.status) {
            prepare.data.errors.forEach(({ msg }) => {
                notification.error({
                    content: msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            });

            // reset balancesRefs
            balancesRefs.value = null;
        } else {
            balancesRefs.value = prepare.data.balances;
        }

        localLoading.value = false;
    };

    const exchange = exchange => refs.exchanges.find(el => el.id === exchange);
    const currency = icon => refs.currencies.find(el => el.id === icon);

    // function handleClick(dontHide) {
    //     if (dontHide) {
    //         return void initModule();
    //     }

    //     collapseItem.value.handleClick(new Event('click'));
    // };

    const hideShow = async ({ expanded }) => {
        if (expanded) {
            initModule();
        }
    };

    const hideModal = (v = null) => {
        if (gl.isMobile) {
            if (!v && route.params.id) {
                router.go(-1);
            }
            show.value = v;
        } else {
            if (route.params.id) {
                router.go(-1);
            }
            show.value = false;
        }
    };

    onMounted(async () => {
        await walletsRefs(false);

        if (route.params.id) {
            show.value = true;
        }
        init.value = true;
    });

    return {
        gl,
        init,
        refs,
        mode,
        show,
        router,
        wallets,
        dataForm,
        applyLabel,
        localLoading,
        collapseItem,
        balancesRefs,
        exchangeInfo,
        symbolBalance,
        apiKeyDataForm,
        apiKeysOptions,
        currencyOptions,
        exchangeOptions,
        canCreateWallet,
        mainButtonLabel,
        canCheckBalance,
        exchangeMetaInfo,
        exchangeMeta2Info,
        isExchangeSelected,
        walletTypesOptions,
        walletApiKeyDialog,
        symbolTotalBalance,
        allSymbolsBalances,
        showAllNonZeroBalances,
        hideShow,
        exchange,
        currency,
        hideModal,
        onActionWallet,
        onCheckBalance,
        onCreateApiKey,
    };
}